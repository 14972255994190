import React from 'react';
import tick from "../../assets/img/common/tick.svg";
import lockImage from "../../assets/img/common/lock.svg";
import starPurple from "../../assets/img/home/star-purple.svg";
import starYellow from "../../assets/img/home/star-yellow.svg";

import coin from "../../assets/img/home/coin.svg";
export const AvatarBox = ({ 
  id, user, img, title, lock, setactiveAvatar, price, type, level, 
  coinRange, userLevel, userCoins, isCurrent 
}) => {

  // Always allow avatar selection (even if locked)
  const handleClick = () => {
    setactiveAvatar({
      id, title, img, lock, price, level, type
    });
  };

  // Format large numbers (e.g., coins)
  const formatNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
    return num.toString();
  };

  const isLocked = () => {
    if (type === "basic") return userLevel < level;
    return !user.purchasedAvatars?.includes(id); // Premium/Epic lock logic
  };

  return (
    <div
      className={`w-full h-[155px] bg-gradient-to-b from-[#443846] to-[#764C6F] 
      rounded-[8px] p-2 cursor-pointer ${isCurrent ? 'border border-[#C121E5]' : ''} ${isLocked() ? 'opacity-50' : ''}` }
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        <h1 className="text-[11.19px] font-normal text-[#fff]">{title}</h1>
        {isCurrent ? (
          <img src={tick} alt="Current Avatar" className="w-[13px]" />
        ) : isLocked() ? (
          <img src={lockImage} alt="Locked" className="w-[13px]" />
        ) : (
          <img src={tick} alt="Unlocked" className="w-[13px]" />
        )}
      </div>

      <div className="flex justify-center mt-2">
        <img src={img} alt={title} className="w-[80%]" />
      </div>

      {type === "basic" ? (
        <div className="flex items-center justify-between mt-2">
          <p className="text-[9.79px] text-[#fff]">Level {level}</p>
          <p className="text-[9.79px] text-[#fff] flex items-center gap-[2px]">
            <img src={coin} className="w-[9px]" alt="Coin" />
            {coinRange}
          </p>
        </div>
      ) : (
        <div className="flex items-center justify-between mt-2">
          <p className="text-[9.79px] text-[#fff]">Buy with</p>
          <p className="text-[9.79px] text-[#fff] flex items-center gap-[2px]">
            <img  src={type === "epic" ? starYellow : starPurple} className="w-[9px]" alt="Coin" />
            {formatNumber(price)}
          </p>
        </div>
      )}
    </div>
  );
};
