import { useEffect } from "react";
import { Layout } from "../components/Layout";
import { checkUserInvitation } from "../api/profile";
import { useNavigate } from "react-router-dom";

export const UserInvite = () => {

  const navigation=useNavigate()

  useEffect(()=>{

    // Get the current URL
      const urlParams = new URLSearchParams(window.location.search);

      // Extract the 'ref' parameter from the URL
      const referrerId = urlParams.get('ref');

    checkUserInvitation({
      userId:referrerId
    },navigation)
  },[])

 


  return (
    <Layout nomenu active={4} hideheaderbg={true}>
      

      <div className="">
        
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center mt-5">Verifying Invitaion Link</h1>
        <p className="text-[18px] font-normal text-[#9A9BA0] text-center">Please wait we are verifying the Invitation Link</p>
      </div>

    </Layout>
  )
}

