import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axiosInstance from '../hooks/axiosInstance';
import { useQueryClient } from '@tanstack/react-query';
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { Loading } from '../pages/Loading';
import { useChooseAvatar, useUpdateCoins } from '../api/profile';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const fetchUserData = async () => {
    const { data } = await axiosInstance.get('/api/me');
    return data;
};

export const AuthProvider = ({ children }) => {
    const queryClient = useQueryClient();
    const [user, setUser] = useState(null);
    const [coinstate, setCoinstate] = useState(0)

    const [loading, setLoading] = useState(true);
    const { mutate: chooseAvatar } = useChooseAvatar();
    const [minLoadingComplete, setMinLoadingComplete] = useState(false);

    const isInitialized = useRef(false);
    const coinBatchRef = useRef(0); // Ref to hold the batched coins
    const { mutate: updateCoins } = useUpdateCoins(); // Backend mutation function
    const addCoins = (earnedCoins, shouldBatch = true) => {
        setCoinstate((prevCoinState) => {
            const newCoinState = prevCoinState + earnedCoins;
            if (shouldBatch) {
                coinBatchRef.current += earnedCoins; // Accumulate earned coins in batch
            }
            return newCoinState; // Update visible state
        });
    };
    console.log("coinBatchRef.current", coinBatchRef.current);
    // Function to flush the batched coins to the backend
    const flushBatch = () => {
        if (coinBatchRef.current > 0) {

            updateCoins({
                amount: coinBatchRef.current,
                type: 'earn',
                description: 'Batch coin update',
            });
            coinBatchRef.current = 0; // Reset batch after flush
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            flushBatch();
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, []);



    useEffect(() => {
        // Initialize coinstate only once when user data is fetched
        if (user?.coins && coinstate === 0) {
            setCoinstate((prevCoinState) => {
                // Prevent resetting if the coinstate is already initialized
                return prevCoinState > 0 ? prevCoinState : Number(user.coins);
            });
        }
    }, [user, coinstate]);



    const login = (rawData, userData) => {
        localStorage.setItem('authToken', rawData); // Save the token (initDataRaw)
        console.log("userData", userData);
        setUser(userData); // Set the user in state
        queryClient.setQueryData(['user'], userData); // Update query cache
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setMinLoadingComplete(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);
    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);
        queryClient.removeQueries(['user']);
    };

    const registerUser = async () => {
        try {
            let userInfo, rawData, startParam;

            if (process.env.REACT_APP_ENV === "local") {
                userInfo = {
                    username: "ProRasel",
                    photoUrl: "https://via.placeholder.com/150",
                    id:774942278
                };
                rawData = 'query_id=AAFGrjAuAAAAAEauMC6CvQJ3&user=%7B%22id%22%3A774942278%2C%22first_name%22%3A%22Rasel%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ProRasel%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730379811&hash=bf362efda23857d46b7cf462786a06ded20a2ef513ac4dd671fbb0af6bdeed64';
            } else {
                const { initData, initDataRaw } = retrieveLaunchParams();
                userInfo = initData.user;
                rawData = initDataRaw;
                // startParam = initData?.startParam;


                if (!userInfo) {
                    throw new Error("Telegram init data not found.");
                }
            }

            const response = await axiosInstance.post("/api/register/user", userInfo);


            if (response.data) {
                login(rawData, response.data.user); // Set user in context after registration
            }
        } catch (error) {
            console.error("Registration failed:", error);
            logout(); // Clear any partial data in case of failure
        } finally {
            setLoading(false); // Hide loading after attempt
        }
    };

    useEffect(() => {

        if (isInitialized.current) return;
        isInitialized.current = true;
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            // Attempt to fetch the user if token exists
            fetchUserData()
                .then((userData) => {
                    setUser(userData);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to fetch user data:", error);
                    logout();
                    setLoading(false);
                });
        } else {
            // No token, initiate registration
            registerUser();
        }
    }, []);
    const updateAvatar = (avatarId) => {
        // Store the previous avatar to revert if necessary
        const previousAvatarId = user.currentAvatar;

        // Optimistically update the user state and cache immediately
        setUser((prevUser) => ({
            ...prevUser,
            currentAvatar: avatarId,
        }));

        queryClient.setQueryData(['user'], (oldData) => ({
            ...oldData,
            currentAvatar: avatarId,
        }));

        // Proceed with the mutation and revert changes if the mutation fails
        chooseAvatar(
            { avatarId },
            {
                onError: (error) => {
                    console.error("Failed to update avatar:", error);

                    // Revert the user state and cache to the previous avatar if the mutation fails
                    setUser((prevUser) => ({
                        ...prevUser,
                        currentAvatar: previousAvatarId, // Revert to previous avatar
                    }));

                    queryClient.setQueryData(['user'], (oldData) => ({
                        ...oldData,
                        currentAvatar: previousAvatarId,
                    }));
                }
            }
        );
    };

   useEffect(() => {
        if (localStorage.getItem('authToken')) {
            console.log("Auth token available, prefetching queries...");

            queryClient.prefetchQuery({
                queryKey: ['energyStatus'],
                queryFn: async () => {
                    const { data } = await axiosInstance.get('/api/energy/status');
                    return data;
                },
            });

            queryClient.prefetchQuery({
                queryKey: ['userTasks'],
                queryFn: async () => {
                    const { data } = await axiosInstance.get('/api/user/tasks');
                    return data;
                },
            });

            queryClient.prefetchQuery({
                queryKey: ['referredUsers'],
                queryFn: async () => {
                    const { data } = await axiosInstance.get('/api/referred-users');
                    return data;
                },
            });
        }
    }, [queryClient,user]);

    return (
        <AuthContext.Provider value={{ user, login, logout, updateAvatar, minLoadingComplete, coinstate, addCoins }}>
            {(!minLoadingComplete) && (

                <Loading />

            )}
            {children}

        </AuthContext.Provider>
    );
};
