import React from 'react';
import closeIcon from '../../assets/img/airdrop/close.svg';

const LevelBonusModal = ({ onClose }) => {
  const bonusData = [
    { name: "RahMan", regular: "5K", premium: "25K" },
    { name: "Devon Lane", regular: "10K", premium: "30K" },
    { name: "Theresa Webb", regular: "15K", premium: "40K" },
    { name: "Ralph Edwards", regular: "20K", premium: "50K" },
    { name: "Arlene McCoy", regular: "25K", premium: "55K" },
    { name: "Jerome Bell", regular: "32K", premium: "63K" },
    { name: "Cody Fisher", regular: "35K", premium: "74K" },
    { name: "Jerome Bell", regular: "43K", premium: "80K" },
    { name: "Brooklyn Simmons", regular: "49K", premium: "90K" },
    { name: "Marvin Murphy", regular: "58K", premium: "110K" },
    { name: "Jacob Jones", regular: "67K", premium: "120K" }
  ];

  return (
    <div className="w-[430px] xs:w-full overflow-hidden max-h-[80vh] bg-[#0C0E1A] border-t-[1px] border-t-[#3E4051] rounded-tl-[16px] rounded-tr-[16px] fixed bottom-0 left-1/2 -translate-x-1/2 z-50 flex flex-col">
      <div className="px-[16px] py-[12px] flex items-center justify-between border-b border-[#35333E]">
        <div>
          <h1 className="text-[20px] font-bold text-[#FFFFFF]">Bonus for levelling up</h1>
          <p className="text-[14px] text-[#9A9BA0]">When your friends reach this you'll be able to receive</p>
        </div>
        <img src={closeIcon} className="cursor-pointer" onClick={onClose} alt="Close" />
      </div>
      
      <div className="overflow-y-auto flex-1">
        <div className="px-4 py-2">
          {/* Header Row */}
          <div className="grid grid-cols-12 gap-2 text-[#9A9BA0] text-[14px] mb-2 px-2">
            <div className="col-span-1">#</div>
            <div className="col-span-5">Name</div>
            <div className="col-span-3">Regular</div>
            <div className="col-span-3">Premium</div>
          </div>

          {/* Data Rows */}
          {bonusData.map((item, index) => (
            <div key={index} className="grid grid-cols-12 gap-2 py-3 px-2 border-b border-[#35333E] text-white">
              <div className="col-span-1 text-[#9A9BA0]">{index + 1}</div>
              <div className="col-span-5 font-medium">{item.name}</div>
              <div className="col-span-3 text-[#9A9BA0]">{item.regular}</div>
              <div className="col-span-3 text-purple-500">{item.premium}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Gradient background effect */}
      <span className="w-[248px] z-[-1] h-[248px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full absolute left-1/2 -translate-x-1/2 top-[200px] blur-[150px] opacity-30"></span>
    </div>
  );
};

export default LevelBonusModal;