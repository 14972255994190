import axios from 'axios';

// Create Axios instance without binding React Query
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Axios Interceptor to inject token dynamically
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `tma ${token}`; 
  }
  return config;
});

export default axiosInstance;
