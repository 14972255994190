import axios from "axios"
import axiosInstance from "../hooks/axiosInstance";
import { useMutation, useQueryClient } from "@tanstack/react-query";


// const URL="http://localhost:3000"
//const URL="https://twinkle-backend.vercel.app"
const URL=process.env.REACT_APP_API_URL;
export const registerUser= async (data)=>{
    try {
     
        const response = await axios.post(`${URL}/api/register/user`, data);
        console.log('url',`${URL}/api/register/user`);
        console.log(response);
        return response.data.message;
    } catch (error) {
        console.error('Registration Error:', error);
     
        throw error; // Re-throw to let the calling function handle it if needed
    }

}



export const updateAvatar=async (data,setuser)=>{
    const response=await axios.post(`${URL}/api/update/avatar`,data)
    console.log(response['data']['message'])
    setuser(response['data']['message'])
}

const updateCoins = async (data) => {
  const response = await axiosInstance.post(`/api/update/coins`, data);
  return response.data.message; // Return message or relevant response
};

export const useUpdateCoins = () => {
  return useMutation({
    mutationFn: updateCoins,
    onSuccess: (data) => {
      console.log('Coins updated successfully:', data);
    },
    onError: (error) => {
      console.error('Error updating coins:', error);
    },
  });
};


export const claimTelegramTask=async (data,setuser)=>{
    const response=await axios.post(`${URL}/api/claimTelegramTask`,data)
    console.log(response['data']['message'])
    setuser(response['data']['message'])
}

export const checkUserInvitation=async (data,navigation)=>{
    const response=await axios.post(`${URL}/api/checkUserInvitation`,data)
    console.log(response['data']['message'])
    navigation("/home")

}

const chooseAvatar = async (data) => {
  const response = await axiosInstance.post(`${URL}/api/choose/avatar`, data);
  return response.data.message; // Return message or relevant data
};

// React Query Hook for Choose Avatar
export const useChooseAvatar = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: chooseAvatar,
    onSuccess: (data) => {
      console.log('Avatar selected successfully:', data);
      queryClient.invalidateQueries(['user']); // Refetch user data
    },
    onError: (error) => {
      console.error('Error selecting avatar:', error);
    },
  });
};

// Purchase Avatar API Call
const purchaseAvatar = async (data) => {
  const response = await axiosInstance.post(`${URL}/api/purchase-avatar`, data);
  return response.data; // Return response data
};

// React Query Hook for Purchase Avatar
export const usePurchaseAvatar = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: purchaseAvatar,
    onSuccess: (data) => {
      console.log('Avatar purchased successfully:', data);
      queryClient.invalidateQueries(['user']); // Refetch user data
    },
    onError: (error) => {
      console.error('Error purchasing avatar:', error);
    },
  });
};