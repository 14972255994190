import { Layout } from "../components/Layout";
import coin from "../assets/img/home/coin.svg"
import { UpdateArea } from "../components/home/UpdateArea";
import badge from "../assets/img/home/badge.svg"
import { useEffect, useState } from "react";
import { registerUser } from "../api/profile";
import { userdata } from "../utils/Function";
import { Link } from "react-router-dom";

import spider from "../assets/img/home/spider.svg"
import gradient from "../assets/img/home/gredient.svg"


export const Level = () => {
  const [user,setuser]=useState({})


  useEffect(() => {
    userdata.username=window.localStorage.getItem('username')
    registerUser(userdata,setuser)

  }, []);

  return (
    <Layout active={1} hideheaderbg={true}>

    <UpdateArea user={user}/>

    <div className="flex items-center justify-center gap-[6px]">
      <img src={badge} alt="" className="w-[40px]" />
      <h1 className="text-[#fff] font-extra-bold text-[32px]">Level {user?.level}</h1>
    </div>



    <div style={{backgroundImage:`url(${spider})`}} className="bg-gradient-to-b from-[#1B152A] to-[#15161B] w-full mt-6 rounded-[23px] py-4 pb-6 bg-no-repeat relative overflow-hidden">
    
    <img src={gradient} alt="" className="absolute top-0 " />
    <img src={user?.defaultavatar?.img} alt="" className="mx-auto mb-2 w-[200px] relative z-10" />
   <div className="flex items-center justify-center flex-col gap-[8px] relative z-30">
   <Link to="/update-avatar" className="font-bold text-[22px] text-[#fff] w-[320px] h-[47.59px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px] flex items-center justify-center">
    Customize your appearance
    </Link>
   </div>
</div>


    </Layout>
  )
}

