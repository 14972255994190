import React, { useEffect, useState } from 'react'
import { Layout } from '../components/Layout'
import first from "../assets/img/onboarding/first.svg"
import mainbg from "../assets/img/onboarding/main-top-bg.svg"
import secondfirst from "../assets/img/onboarding/second/first.svg"
import secondsecond from "../assets/img/onboarding/second/second.svg"
import secondthird from "../assets/img/onboarding/second/third.svg"
import secondfourth from "../assets/img/onboarding/second/fourth.svg"
import secondfifth from "../assets/img/onboarding/second/fifth.svg"
import secondsixth from "../assets/img/onboarding/second/sixth.svg"
import secondseven from "../assets/img/onboarding/second/seven.svg"
import secondeight from "../assets/img/onboarding/second/eight.svg"
import secondnine from "../assets/img/onboarding/second/nine.svg"

import invite from "../assets/img/onboarding/third/invite.svg"
import fourmain from "../assets/img/onboarding/four/four.svg"
import fifth from "../assets/img/onboarding/fifth/fifth.svg"
import { Link, useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import { registerUser } from '../api/profile'
import { userdata } from '../utils/Function'
import { useAuth } from '../context/AuthContext'
import { retrieveLaunchParams } from '@telegram-apps/sdk';

export const Onboarding = () => {
    const [active, setactive] = useState(1);
    const { user, login } = useAuth();
    const navigate = useNavigate()
       useEffect(() => {
        // Check if the user has already completed or skipped onboarding
        const hasSkippedOnboarding = localStorage.getItem('hasSkippedOnboarding');
        if (hasSkippedOnboarding) {
            navigate('/home');
        }
    }, [navigate]);
    const dataOnboarding = [
        {
            heading: "Tap and earn coin",
            paragraph: "You can tap using your finger and boosters to earn game coin"
        },
        {
            heading: "Upgrade Avatar",
            paragraph: "Upgrade your avatar and get more coins!"
        },
        {
            heading: "Invite friends",
            paragraph: "You and your friend will both receive coins for invite!"
        },
        {
            heading: "Subscribe",
            paragraph: "Subscribe to our community and stay updated"
        },
        {
            heading: "Use coins to get an AirDrop at token listing!",
            paragraph: "Don’t forget to invite your friends. Good luck!"
        }
    ]
  const handleSkip = () => {
        // Store skip status in localStorage
        localStorage.setItem('hasSkippedOnboarding', 'true');
        navigate('/home');
    };


    useEffect(() => {


        // if (window.TelegramGameProxy) {
        //     window.TelegramGameProxy.setResizeMode('fullscreen');
        // }

        // // Get the current URL
        // const urlParams = new URLSearchParams(window.location.search);

        // // Extract the 'ref' parameter from the URL
        // const username = urlParams.get('username');
        // const img = urlParams.get('img');

        // let getter=window.localStorage.getItem("username")

        // if(getter&&getter!="null"){


        //     window.localStorage.setItem("username",username)

        //     window.localStorage.setItem("img",img)

        //     // navigate("/home")
        // }



    }, [])

    // Function to handle the authentication
   


    return (
        <Layout active={1} nomenu={true} hideheaderbg={true}  >
            <div>
                <img src={mainbg} loading="lazy" alt="" className='absolute left-0 -top-[60px] w-full' />
                <div className='relative z-10'>

                    {/* first */}
                    <img src={first} loading="lazy" alt="" className={`mx-auto mt-10 hidden ${active == 1 && '!block'}`} />

                    {/* second */}
                    <div className={`hidden grid-cols-3 gap-3 ${active == 2 && '!grid'}`}>
                        <img src={secondfirst} loading="lazy" alt="" />
                        <img src={secondsecond} loading="lazy" alt="" />
                        <img src={secondthird} loading="lazy" alt="" />
                        <img src={secondfourth} loading="lazy" alt="" />
                        <img src={secondfifth} loading="lazy" alt="" />
                        <img src={secondsixth} loading="lazy" alt="" />
                        <img src={secondseven} loading="lazy" alt="" />
                        <img src={secondeight} loading="lazy" alt="" />
                        <img src={secondnine} loading="lazy" alt="" />
                    </div>
                    {/* third */}
                    <img src={invite} loading="lazy" alt="" className={`mx-auto mt-10 hidden ${active == 3 && '!block'}`} />
                    {/* fourth */}
                    <div className={`hidden ${active == 4 && '!block'}`}>
                        <img src={fourmain} loading="lazy" alt="" className='mx-auto mt-10' />
                        <h1 className='text-[34px] text-center mt-4 mb-2 font-bold text-[#fff]'>
                            <span className='text-[#F4BE0F]'>1M users</span> are <br /> already with us!</h1>

                        <a href="#" className='w-[334px] xs:w-[80%] h-[56px] rounded-[12px] bg-[#5A60FF] text-[22px] font-bold text-[#FFFFFF] flex items-center justify-center mx-auto mb-[31px]'>
                            Join the TG Channel
                        </a>
                    </div>
                    {/* fifth */}
                    <img src={fifth} alt="" loading="lazy" className={`mx-auto mt-10 hidden ${active == 5 && '!block'}`} />

                    <div className='fixed left-1/2 -translate-x-1/2 bottom-5 px-4 max-w-[430px] w-[100%]'>
                        <div className='w-full   py-[48px] px-[20px] bg-gradient-to-b from-[#6B4265]
             via-[#2C1C3B] to-[#2C1C3B] rounded-[23px] border-[1px] border-[#474852]'>
                            <h1 className='text-[24px] text-center font-bold text-[#fff]'>
                                {dataOnboarding[active - 1]['heading']}
                            </h1>
                            <p className='text-[25px] text-center text-[#CBCCD3] mt-[16px]'>
                                {dataOnboarding[active - 1]['paragraph']}
                            </p>
                            <div className='flex items-center gap-[7px] mt-[24px] justify-center'>
                                <span className={`bg-[#5F5069] block w-[10px] h-[10px] rounded-full ${active == 1 && '!bg-[#fff] !w-[32px]'}`}></span>
                                <span className={`bg-[#5F5069] block w-[10px] h-[10px] rounded-full ${active == 2 && '!bg-[#fff] !w-[32px]'}`}></span>
                                <span className={`bg-[#5F5069] block w-[10px] h-[10px] rounded-full ${active == 3 && '!bg-[#fff] !w-[32px]'}`}></span>
                                <span className={`bg-[#5F5069] block w-[10px] h-[10px] rounded-full ${active == 4 && '!bg-[#fff] !w-[32px]'}`}></span>
                                <span className={`bg-[#5F5069] block w-[10px] h-[10px] rounded-full ${active == 5 && '!bg-[#fff] !w-[32px]'}`}></span>
                            </div>
                            <div className='grid grid-cols-2 gap-3 mt-10'>


                                {
                                    active === 5 && (
                                        <button to="/home" onClick={handleSkip} className='bg-gradient-to-tr flex items-center justify-center col-span-2 from-[#C121E5]  to-[#8B25F5] w-full h-[46px] text-[18px] text-[#fff] font-bold rounded-[12px]'>Play Now</button>
                                    )
                                }



                                {
                                    active < 5 && (
                                        <>

                                            <button onClick={handleSkip} className='bg-[#2E293C] flex items-center justify-center w-full h-[46px] text-[18px] text-[#9A9BA0] rounded-[12px]'>Skip</button>
                                            <button onClick={e => {
                                                setactive(active + 1)
                                            }} className='bg-gradient-to-tr from-[#C121E5]  to-[#8B25F5] w-full h-[46px] text-[18px] text-[#fff] font-bold rounded-[12px]'>Next</button>
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
