import React, { useState } from 'react';
import { Layout } from "../components/Layout";
import { UpdateArea } from "../components/home/UpdateArea";
import earnCoin from "../assets/img/task/coin.png";
import coinIcon from "../assets/img/home/coin.png";
import { DailyRewardRec } from "../components/task/DailyRewardRec";
import { useAuth } from "../context/AuthContext";
import { TaskModal } from '../components/TaskModal';
import { useCompleteTask, useUserTasks } from '../api/taskApi';
import { useReferredUsers } from '../api/referrals';

export const Task = () => {
  // const { tasks, tasksLoading, tasksError, completeTask } = useTask();
  const { data: tasks, isLoading: tasksLoading, error: tasksError } = useUserTasks();
  const completeTaskMutation = useCompleteTask();

  const completeTask = (taskId) => {
    return completeTaskMutation.mutateAsync(taskId); // Use mutateAsync for Promise support
  };
  const { addCoins,user } = useAuth();

  const [selectedTask, setSelectedTask] = useState(null);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [coins, setCoins] = useState([]);
  const handleTaskClick = (task) => {
    setSelectedTask(task); // Open the modal with the selected task
    setIsLoadingFollow(false); // Reset loading states when a new task is selected
    setIsLoadingCheck(false);

  };
  const { data: referredUsers} = useReferredUsers(user?._id);

  const triggerCoinAnimation = () => {
    const newCoins = Array.from({ length: 15 }, (_, i) => ({
      id: i,
      left: Math.random() * 80 + 10,
      delay: Math.random() * 0.5,
    }));
    setCoins(newCoins);

    // Clear coins after animation duration
    setTimeout(() => setCoins([]), 1600);
  };
  // Handler for "Follow" button in modal
  const handleFollowClick = () => {
    if (!selectedTask?.actionLink) return;

    setIsLoadingFollow(true);
    // Open the action link in a new tab as per original design
    window.open(selectedTask.actionLink, "_blank");

    // Simulate a delay before enabling the "Check" button
    setTimeout(() => {
      setIsLoadingFollow(false);
    }, 100);
  };

  // Handler for "Check" button in modal
  const handleTaskCheck = async () => {
    if (!selectedTask) return;

    setIsLoadingCheck(true); // Show loader on "Check" button
    try {
      if(selectedTask.taskType === 'invite' && referredUsers?.length < 3) {
        alert('You need to invite 3 friends to complete this task.');
        return;

      }
      addCoins(selectedTask.reward, false);
      setSelectedTask(null); // Close the modal after task is marked complete
      triggerCoinAnimation();
      await completeTask({ taskId: selectedTask._id });

    } catch (error) {
      console.error("Error completing task:", error);
    } finally {
      setIsLoadingCheck(false); // Stop loader
    }
  };
  const sortedTasks = tasks?.slice().sort((a, b) => {
    if (a.completed === b.completed) return 0; // Keep original order if both are completed or not
    return a.completed ? 1 : -1; // Move completed tasks to the bottom
  });

  return (
    <Layout active={3} hideheaderbg={true}>
      {/* <UpdateArea user={user} /> */}

      <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[150px]" />


      <div className="pt-20">

        <img src={earnCoin} alt="" className="mx-auto h-14" />
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center mt-5">Earn coins</h1>
        <p className="text-[18px] font-normal text-[#9A9BA0] text-center">Claim coins everyday or finish tasks</p>
      </div>

      <DailyRewardRec rewardData={tasks} loading={tasksLoading} />

      <div className=''>
        <h1 className="flex items-center gap-1 text-[18px] font-bold text-[#FFFFFF]">

          Tasks
        </h1>
        <ul className="flex flex-col  mt-2 gap-2">
          {!tasksLoading && sortedTasks
            ?.filter((task) => task.taskType !== 'connect') // Filter out tasks with taskType 'connect'
            ?.map((task) => (
              <li
                key={task._id}
                className={`border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2 ${task.completed ? 'opacity-50' : ''}`}
              >
                <img src={require(`../assets/img/task/${task.icon}`)} className="w-[25px] h-[25px]" alt={task.title} />
                <h1 className="text-[18px] text-[#fff] font-semibold flex-1">{task.title}</h1>
                {task.completed ? (
                  <button className="flex items-center  gap-[6px] w-[106px] bg-[#3D3E49] h-[35px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">Done</button>
                ) : (
                  <button
                    onClick={() => handleTaskClick(task)} // Open modal on task click
                    className={`flex items-center gap-[6px] w-[106px] bg-[#3D3E49] h-[35px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E] ${task.taskType === 'connect' ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={task.taskType === 'connect'}
                  >
                    <img src={coinIcon} alt="Coin" className="w-[20px] h-[20px]" />
                    +{task?.reward?.toLocaleString()}
                  </button>
                )}
              </li>
            ))}
        </ul>
      </div>

      {/* Task Modal */}
      {selectedTask && (
        <TaskModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onCheck={handleTaskCheck}
          isLoadingFollow={isLoadingFollow}
          isLoadingCheck={isLoadingCheck}
          onFollow={handleFollowClick}
        />
      )}
      {coins.map((coin) => (
        <img
          key={coin.id}
          src={coinIcon}
          alt="Coin"
          className="coin-animation"
          style={{
            left: `${coin.left}%`,
            animationDelay: `${coin.delay}s`,
          }}
        />
      ))}
    </Layout>
  );
};
