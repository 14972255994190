import React, { useState } from 'react';
import { Layout } from "../components/Layout";
import { Bonus } from "../components/bonus/Bonus"; // Adjust the import path as needed
import { UpdateArea } from '../components/home/UpdateArea';
import { useAuth } from '../context/AuthContext';

const DailyRewardPage = () => {
  const [bonusActive, setBonusActive] = useState(true);
  const { user, login } = useAuth();

  return (
       <Layout active={3} hideheaderbg={true}>
                <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[150px]" />

      {/* <UpdateArea user={user} /> */}
      {bonusActive && (
        <Bonus setbonusactive={setBonusActive} />
      )}
    </Layout>
  );
};

export default DailyRewardPage;
