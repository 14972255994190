import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReward } from '../../context/RewardContext';
import bonusIcon from '../../assets/img/boost/bonus.svg';
import coinIcon from "../../assets/img/home/coin.png"

import closeIcon from '../../assets/img/airdrop/close.svg';
import { useClaimDailyReward, useGetDailyReward } from '../../api/reward';
import { useAuth } from '../../context/AuthContext';

export const Bonus = ({ setBonusActive }) => {
  const { data: rewardData, isLoading, error } = useGetDailyReward();
  const { addCoins } = useAuth();
  const [isClaiming, setIsClaiming] = useState(false);
  const navigate = useNavigate();
  const { mutate: claimReward } = useClaimDailyReward();
  const dailyBonuses = [
    { day: "Day 1", amount: "5k" },
    { day: "Day 2", amount: "20k" },
    { day: "Day 3", amount: "50k" },
    { day: "Day 4", amount: "100k" },
    { day: "Day 5", amount: "250k" },
    { day: "Day 6", amount: "500k" },
    { day: "Day 7", amount: "800k" },
    { day: "Day 8", amount: "1M" },
    { day: "Day 9", amount: "2M" },
    { day: "Day 10", amount: "3M" },
    { day: "Day 11", amount: "4M" },
    { day: "Day 12", amount: "5M" }
  ];

  const nextClaimableDay = rewardData?.rewardDay + 1;

  const handleClaimReward = (bonusAmount) => {
    setIsClaiming(true);

    claimReward();
    addCoins(bonusAmount, false);
    setIsClaiming(false);
  };
  if (isLoading) return <p>Loading...</p>;

  return (
    <div className=" xs:w-full overflow-auto bg-[#0C0E1A]   rounded-tl-[16px] rounded-tr-[16px]  z-50 flex flex-col pb-24">
      <div className="px-[16px] py-[12px]  text-right z-50">
        {/* <h1 className="text-[20px] font-bold text-white">Daily Bonuses</h1> */}
        <img
          src={closeIcon}
          className="cursor-pointer"
          onClick={() => navigate('/home')}
          alt="Close"
        />
      </div>

      <div className="px-4 flex flex-col items-center text-center flex-1 pt-4">
        <img src={bonusIcon} alt="Bonus Icon" />
        <h1 className="text-[36px] text-white font-bold mt-[5px]">Daily Bonuses</h1>
        <p className="font-normal text-[18px] text-[#9A9BA0] mt-[5px]">Log in every day and claim your bonus!</p>

        <div className="w-full grid gap-2 mt-[10px] grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 grid-cols-4">
          {dailyBonuses.map((bonus, index) => {
            const dayIndex = index + 1;
            const isCurrentDay = rewardData?.rewardDay === dayIndex && rewardData.isClaimAvailable;
            const isClaimedDay = dayIndex <= rewardData?.rewardDay; // All claimed days, up to today
            const isNextClaimableDay = dayIndex === nextClaimableDay && !rewardData.isClaimAvailable;

            // Determine the class for each box based on its status
            const boxClass = isCurrentDay
              ? 'border-2 border-[#8D2EED] p-[1px]' // Current day with purple border, claimable
              : isClaimedDay
                ? ' border-2 opacity-50  border-gray-400 bg-gradient-to-tr from-[#C121E5] to-[#8B25F5]' // Claimed days with purple background
                : isNextClaimableDay
                  ? 'border-2 border-[#8D2EED] p-[1px]' // Next claimable day with high opacity
                  : ' border-2 border-gray-400 opacity-50 bg-[rgba(255,255,255,0.08)]'; // Future days with low opacity

            return (
              <div
                key={bonus.day}
                className={`cursor-pointer rounded-[12px] h-[118px] flex items-center flex-col justify-center ${boxClass} backdrop-blur-[20px]`}
              >
                <div className="flex flex-col items-center justify-center w-full h-full bg-[#0C0E1A] rounded-[12px]">
                  <h1 className="text-[#9A9BA0] text-[16px]">{bonus.day}</h1>
                  <img src={coinIcon} className="w-[45px] h-[45px]" alt="Coin Icon" />
                  <p className="text-[20px] text-[#F4BE0F] font-semibold">{bonus.amount}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="fixed bottom-[100px] left-1/2 transform -translate-x-1/2 z-50">
          {rewardData.isClaimAvailable ? (
            <button
              disabled={isClaiming}
              onClick={() => handleClaimReward(rewardData.rewardAmount)}
              className="font-bold text-[22px] text-white w-[200px] h-[40px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px] shadow-lg"
            >
              Claim now
            </button>
          ) : (
            <p className="text-[18px] font-bold text-[#9A9BA0]">
              Come back tomorrow!
            </p>
          )}
        </div>


      </div>

      <span className="w-[248px] h-[248px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full absolute left-1/2 -translate-x-1/2 top-[200px] blur-[150px]"></span>
    </div>
  );
};
