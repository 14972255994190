import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDailyReward } from '../../api/reward';
import rewardIcon from '../../assets/img/task/reward.png';
import tick from "../../assets/img/common/tick.svg";

export const SmallDailyReward = ({ img, type, imgstyle = null }) => {
  const { data: rewardData, loading, error } = useGetDailyReward();
  const [formattedTime, setFormattedTime] = useState(null);

  // Helper function to format remaining time in HH:MM:SS format with padding
  const convertMillisecondsToTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return { hours, minutes, seconds: secs };
  };

  useEffect(() => {
    if (rewardData && rewardData.remainingTime !== undefined && rewardData.requestTime !== undefined) {
      const intervalId = setInterval(() => {
        const utcNow = new Date().getTime();
        const elapsedTime = utcNow - rewardData.requestTime;
        const remaining = rewardData.remainingTime - elapsedTime;

        if (remaining <= 0) {
          clearInterval(intervalId);
          setFormattedTime({ hours: "00", minutes: "00", seconds: "00" });
        } else {
          setFormattedTime(convertMillisecondsToTime(remaining));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [rewardData]);

  return (
    <div className="flex flex-col items-center justify-center relative">
      {/* Add conditional border and tick icon */}
      <div className={`w-full blur-small-boxes h-[110px] rounded-[12px] flex flex-col items-center justify-center z-10
        ${!rewardData?.isClaimAvailable ? 'border border-[#C121E5]' : ' boxBorder'}`}
      >
        {/* Tick icon if the timer is active */}
        {!rewardData?.isClaimAvailable && (
          <img src={tick} alt="Tick" className="w-[13px] absolute right-2 top-2" />
        )}

        <img src={rewardIcon} className='h-8' loading="lazy" alt="Reward Icon" />
        <h1 className="text-[14px] dailyBonusBtnTitle text-[#D2D3D9] mt-2">{type}</h1>
        
        {loading ? (
          <p className="font-medium text-[12px] gap-2 text-[#fff] w-[95px] h-[30px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center">...</p>
        ) : rewardData?.isClaimAvailable ? (
          <Link
            to="/daily-rewards"
            className="font-medium text-[12px] gap-2 text-[#fff] w-[70px] h-[25px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center"
          >
            Claim 
          </Link>
        ) : (
          <p className="font-medium text-[12px] gap-2 text-[#fff] w-[70px] h-[25px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center">
            {formattedTime ? `${formattedTime.hours}:${formattedTime.minutes}:${formattedTime.seconds}` : "00:00:00"}
          </p>
        )}
      </div>
    </div>
  );
};
