export let userdata={
    name: "Easin Arafat",
    username: "EasinArafat123",
    password: "thisistheuser",
    profileImg: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    level: "1",
    coins: "0",
    defaultavatar:{
      title:"Default",
      img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/one.png?alt=media&token=86284faa-7ca0-445c-b873-72d88ac6ce47",
      lock:false,
      coin:1
    },
    avatars:[
      {
        title:"Default",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/one.png?alt=media&token=86284faa-7ca0-445c-b873-72d88ac6ce47",
        lock:false,
        coin:1,
        level:1,
        coinRange:"0-100k",
        type:"basic"
      },
      {
        title:"Twix Star",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/two.png?alt=media&token=ca7e1516-464f-44f0-83c8-908637b6701f",
        lock:true,
        coin:2,
        price:100000,
        level:2,
        coinRange:"100k-500k",
        type:"basic"
      },
     
      {
        title:"Glowy",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/three.png?alt=media&token=1e6dc2f0-16b6-48d6-ac2d-2be3672abf14",
        lock:true,
        coin:2,
        price:500000,
        level:3,
        coinRange:"500k-2M",
        type:"basic"
      },
      {
        title:"Blim",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/four.png?alt=media&token=9c3ac910-c164-4079-b07b-44f33fad4218",
        lock:true,
        coin:2,
        price:2000000,
        level:4,
        coinRange:"2M-10M",
        type:"basic"
      },
      {
        title:"Shiny",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/five.png?alt=media&token=92b06ea7-1771-41bf-9417-e3c0a639f978",
        lock:true,
        coin:2,
        price:10000000,
        level:5,
        coinRange:"10M-50M",
        type:"basic"
      },
      {
        title:"Wink",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/six.png?alt=media&token=5ac9b460-74f1-4628-b6db-2cdb18ccab7d",
        lock:true,
        coin:2,
        price:50000000,
        level:6,
        coinRange:"50M-100M",
        type:"basic"
      },
      {
        title:"Zappy",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/seven.png?alt=media&token=f7a46a11-19b9-496a-a1c4-433dfb50ce25",
        lock:true,
        coin:2,
        price:100000000,
        level:7,
        coinRange:"100M-500M",
        type:"basic"
      },
      {
        title:"Blink",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/eight.png?alt=media&token=60442069-498d-42d8-8a1b-72b3d2c84511",
        lock:true,
        coin:2,
        price:500000000,
        level:8,
        coinRange:"500M-1B",
        type:"basic"
      },
      {
        title:"Fizzy",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/nine.png?alt=media&token=5c2a8963-bbd3-40c3-b4dc-806fea4ff211",
        lock:true,
        coin:2,
        price:1000000000,
        level:9,
        coinRange:"1B-10B",
        type:"basic"
      },
      {
        title:"Lumi",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/ten.png?alt=media&token=fb08635d-6768-4dad-ad14-48d88dcddf57",
        lock:true,
        coin:2,
        price:10000000000,
        level:10,
        coinRange:"10B-20B",
        type:"basic"
      },

      {
        title:"Cute Boy",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015.png?alt=media&token=2b3ded12-9d9e-405a-8324-b107207970a1",
        lock:true,
        coin:5,
        price:100000,
        level:null,
        coinRange:null,
        type:"premium"
      },

      {
        title:"Dancing Girl",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(1).png?alt=media&token=26c44e83-a19e-489a-ac80-e7330672901c",
        lock:true,
        coin:5,
        price:200000,
        level:null,
        coinRange:null,
        type:"premium"
      },
      {
        title:"Baby Doll",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(2).png?alt=media&token=2704bb09-3b74-426f-82ca-6348e7d89215",
        lock:true,
        coin:5,
        price:500000,
        level:null,
        coinRange:null,
        type:"premium"
      },
      {
        title:"Hot Girl",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(3).png?alt=media&token=c550a43e-91df-454e-a8b4-8a09ee1404f9",
        lock:true,
        coin:5,
        price:1000000,
        level:null,
        coinRange:null,
        type:"premium"
      },
      {
        title:"Baby Queen",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(4).png?alt=media&token=5f5feab8-b152-498f-ab97-b893cac5761b",
        lock:true,
        coin:5,
        price:10000000,
        level:null,
        coinRange:null,
        type:"premium"
      },

      {
        title:"Alien Boss",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(5).png?alt=media&token=51af3f11-a3eb-47f2-ac84-bddea38f2fc6",
        lock:true,
        coin:10,
        price:50000000,
        level:null,
        coinRange:null,
        type:"epic"
      },
     
      {
        title:"Robot Girl",
        img:"https://firebasestorage.googleapis.com/v0/b/twinkle-a9172.appspot.com/o/Group%2015%20(6).png?alt=media&token=9da6694f-747a-4a17-8927-4b25a218b01e",
        lock:true,
        coin:10,
        price:100000000,
        level:null,
        coinRange:null,
        type:"epic"
      },
    ]
}