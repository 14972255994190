import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import axiosInstance from '../hooks/axiosInstance';
import { useAuth } from './AuthContext';

const LevelContext = createContext();

export const useLevel = () => useContext(LevelContext);

export const LevelProvider = ({ children }) => {

  const {user} = useAuth();
  const [levels, setLevels] = useState([]);
 const isInitialized = useRef(false);

  useEffect(() => {
    const fetchLevelConfig = async () => {
      try {
        const { data } = await axiosInstance.get('/api/level-config');
        setLevels(data);
      } catch (error) {
        console.error('Failed to fetch level config:', error);
      }
    };
     if (isInitialized.current) return; // Prevent re-execution

     if(user){
        isInitialized.current = true; // Mark as initialized

    fetchLevelConfig();
    }
  }, [user]);

const getLevelDetails = (level) => {
  console.log('Levels:', levels);
  return levels?.find((l) => Number(l.level) === Number(level)) || null;
};


  return (
    <LevelContext.Provider value={{ levels, getLevelDetails }}>
      {children}
    </LevelContext.Provider>
  );
};
