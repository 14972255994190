import React, { useEffect } from 'react';
import { Layout } from '../components/Layout';
import main from "../assets/img/loading/Loading.png";
import spider from "../assets/img/loading/spider.svg";
import x from "../assets/img/loading/x.svg";
import telegram from "../assets/img/loading/telegram.svg";
import youtube from "../assets/img/loading/youtube.svg";

export const Loading = () => {
  // Disable body scrolling when the component is mounted
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll'); // Clean up on unmount
  }, []);

  return (
    <Layout active={1} nomenu={true}>
      {/* Fullscreen overlay to cover background elements */}
      <div className="fixed inset-0 bg-black  z-40"></div>
      
      {/* Progress bar at the top */}
      <div className="fixed top-0 left-0 h-[3.5px] rounded progress-bar-loader z-50"></div>
      
      <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[250px] z-50" />

      {/* Main loading content */}
      <div className='relative -mx-4 flex flex-col justify-center items-center min-h-screen overflow-hidden fullscreen-layout z-50'>
        <span className='w-[348px] h-[348px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full blur-[250px] absolute left-1/2 -translate-x-1/2 top-[400px]'></span>
        <img src={spider} alt="" className="absolute -top-[80px] z-50 w-full" />
        <img src={main} alt="" className='mx-auto w-[250px] my-10 relative z-50' />
        
        <div className='flex flex-col items-center gap-2 relative z-50'>
          <h1 className='text-[28px] text-[#fff] font-extrabold text-center'>Twinkle Token ($TWKL)</h1>
          <p className='text-[26px] text-center text-[#9A9BA0]'>will be launched</p>
          <p className='text-[48px] text-[#fff] font-extrabold'>on Ton</p>
          <p className='text-[26px] text-center text-[#9A9BA0]'>Stay tuned</p>
          
          {/* Update this text to maintain consistent center alignment */}
          <h1 className='text-[16px] font-bold text-[#fff] mb-[8px] text-center leading-tight'>
            More info in official channels
          </h1>
          <nav className='flex items-center gap-3 w-[50%] mx-auto'>
            <a href="https://x.com/twinkles_game?s=21&t=RlmEnZOJ0IXn6kDvVawA9g" target="_blank" rel="noreferrer">
              <img src={x} alt="" />
            </a>
            <a href="https://t.me/twinklecommunity" target="_blank" rel="noreferrer">
              <img src={telegram} alt="" />
            </a>
            <a href="https://www.youtube.com/@Twinkles_Official" target="_blank" rel="noreferrer">
              <img src={youtube} alt="" />
            </a>
          </nav>
        </div>
      </div>
    </Layout>
  );
};
