// src/context/EnergyContext.js
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useGetEnergyStatus, useApplyFullEnergy, useUpdateEnergy } from '../api/energy';
import { Loading } from '../pages/Loading';
import { useLevel } from './LevelContext';
import { useAuth } from './AuthContext';

const EnergyContext = createContext();

export const useEnergy = () => useContext(EnergyContext);

export const EnergyProvider = ({ children }) => {
  const [isRefilling, setIsRefilling] = useState(false);
  const [unsyncedEnergy, setUnsyncedEnergy] = useState(0);
  const { data: initialEnergyStatus, error } = useGetEnergyStatus();
  const { mutateAsync: applyFullEnergy, status } = useApplyFullEnergy();
  const { mutate: updateEnergyInBackend } = useUpdateEnergy(); // Use mutation to update backend
  const [coinPerTap, setCoinPerTap] = useState(1);
  const [energyStatus, setEnergyStatus] = useState(null);
  const refillIntervalRef = useRef(null); // Ref for refill interval
  const syncIntervalRef = useRef(null); // Ref for sync interval
  const { getLevelDetails } = useLevel(); // Fetch level configurations
  const { user } = useAuth(); // Fetch user details
  const coinPerTapRef = useRef(coinPerTap);

  useEffect(() => {
    coinPerTapRef.current = coinPerTap; // Keep ref updated with the latest value
  }, [coinPerTap]);
  useEffect(() => {
    if (user?.level) {
      const userLevelDetails = getLevelDetails(user.level);
      console.log('userLevelDetails', userLevelDetails);
      setCoinPerTap(userLevelDetails?.coinPerTap || 1); // Update dynamically
    }
  }, [user, getLevelDetails]);
  const refillEnergy = () => {
    if (!energyStatus || isRefilling || energyStatus.current >= energyStatus.max) return;

    setIsRefilling(true);
    refillIntervalRef.current = setInterval(() => {
        setEnergyStatus((prevStatus) => {
            if (!prevStatus) return prevStatus;

            const newEnergy = Math.min(prevStatus.current + coinPerTapRef.current, prevStatus.max);

            setUnsyncedEnergy((prev) => {
                const updatedUnsynced = prev + coinPerTapRef.current;

                // Update backend if threshold is reached
                if (updatedUnsynced >= 10) {
                    updateEnergyInBackend(newEnergy);
                    return 0; // Reset unsynced energy
                }

                return updatedUnsynced;
            });

            if (newEnergy >= prevStatus.max) {
                stopRefilling(); // Stop refilling when max energy is reached
            }

            return { ...prevStatus, current: newEnergy };
        });
    }, 1000);

    if (!syncIntervalRef.current) {
        syncIntervalRef.current = setInterval(syncEnergyToBackend, 5000);
    }
};

  const stopRefilling = () => {
    if (refillIntervalRef.current) {
        clearInterval(refillIntervalRef.current);
        refillIntervalRef.current = null;
    }
    if (syncIntervalRef.current) {
        clearInterval(syncIntervalRef.current);
        syncIntervalRef.current = null;
    }

    if (energyStatus) {
        updateEnergyInBackend(energyStatus.current); // Final backend update
    }

    setUnsyncedEnergy(0); // Reset after final sync
    setIsRefilling(false);
};


const syncEnergyToBackend = () => {
    if (unsyncedEnergy > 0) {
        const newEnergy = Math.min(energyStatus.current, energyStatus.max);
        updateEnergyInBackend(newEnergy);
        setUnsyncedEnergy(0); // Reset batch after sync
    }
};


  useEffect(() => {
    if (energyStatus && energyStatus.current < energyStatus.max) {
      refillEnergy(); // Always ensure refill starts when energy is below max
    }
  }, [energyStatus]); // Depend on `energyStatus` to trigger when it updates


  useEffect(() => {
    return () => {
      if (unsyncedEnergy > 0) {
        const newEnergy = Math.min(energyStatus.current, energyStatus.max);
        updateEnergyInBackend(newEnergy);
      }
      stopRefilling(); // Ensure intervals are cleared
    };
  }, []);

  useEffect(() => {
    if (initialEnergyStatus) {
      setEnergyStatus(initialEnergyStatus);
    }
  }, [initialEnergyStatus]);
  useEffect(() => {
    return () => {
      if (refillIntervalRef.current) {
        clearInterval(refillIntervalRef.current);
        refillIntervalRef.current = null;
      }
      if (syncIntervalRef.current) {
        clearInterval(syncIntervalRef.current);
        syncIntervalRef.current = null;
      }
      setIsRefilling(false); // Reset refilling state
    };
  }, []);

  const decreaseEnergy = (amount) => {
    setEnergyStatus((prevStatus) => {
      if (!prevStatus) return prevStatus;

      const newCurrentEnergy = Math.max(prevStatus.current - amount, 0);

      // Update the backend with the new energy level
     // updateEnergyInBackend(newCurrentEnergy);

      return {
        ...prevStatus,
        current: newCurrentEnergy,
      };
    });
  };

  return (
    <EnergyContext.Provider value={{ energyStatus, loading: status === 'pending', error, refillEnergy, applyFullEnergy, decreaseEnergy, stopRefilling }}>
      {children}

    </EnergyContext.Provider>
  );
};
