import { Layout } from "../components/Layout";
import { CurrentAvatar } from "../components/home/CurrentAvatar";
import { AvatarBox } from "../components/avatar/AvatarBox";
import { useEffect, useState } from "react";
import { userdata } from "../utils/Function";
import { registerUser } from "../api/profile";
import avatars from "../configs/avatarsConfig";
import { useAuth } from "../context/AuthContext";
import { Loading } from "./Loading";
export const Avatar = () => {

  const [active, setactive] = useState("basic");

  const { user, updateAvatar, minLoadingComplete } = useAuth(); // Access minLoadingComplete from context

  const [activeAvatar, setactiveAvatar] = useState(null);

  useEffect(() => {
    // Set activeAvatar when user is available and the currentAvatar exists
    if (user?.currentAvatar) {
      const current = avatars.find((avatar) => avatar.id === user.currentAvatar);
      setactiveAvatar(current);
    }
  }, [user]);



  const filteredAvatars = avatars.filter((avatar) => avatar.type === active);
  return (

    <Layout active={1} hideheaderbg={true}>
      {!user || !activeAvatar ? (
        <span>..</span>
      ) : (
        <>
          <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[150px]" />

          <div className="pt-10">
            <h1 className="text-[#fff] font-bold text-[18px]">Your Skin</h1>
            <CurrentAvatar updateAvatar={updateAvatar} user={user} activeAvatar={activeAvatar} />
          </div>

          <div className="avatar-splide mt-3">
            <div className="grid grid-cols-3 gap-2 mt-7 mb-4">
              <button onClick={e => {
                setactive("basic")
              }} className={`font-medium text-[16px] text-[#9A9BA0] h-[40px] rounded-[8px] bg-[#3F3345] border-[1px] border-[#ffffff2d] w-full ${active === "basic" && '!bg-gradient-to-tr !from-[#C121E5] !to-[#8B25F5] !border-[transparent] !text-[#fff]'}`}>
                Basic
              </button>
              <button onClick={e => {
                setactive("premium")
              }} className={`font-medium text-[16px] text-[#9A9BA0] h-[40px] rounded-[8px] bg-[#3F3345] border-[1px] border-[#ffffff2d] w-full ${active === "premium" && '!bg-gradient-to-tr !from-[#C121E5] !to-[#8B25F5] !border-[transparent] !text-[#fff]'}`}>
                Premium
              </button>
              <button onClick={e => {
                setactive("epic")
              }} className={`font-medium text-[16px] text-[#9A9BA0] h-[40px] rounded-[8px] bg-[#3F3345] border-[1px] border-[#ffffff2d] w-full ${active === "epic" && '!bg-gradient-to-tr !from-[#C121E5] !to-[#8B25F5] !border-[transparent] !text-[#fff]'}`}>
                Epic
              </button>
            </div>
            <div>


              {
                user.coins && (

                  <div className="grid grid-cols-3 gap-2">
                    {
                      filteredAvatars.map((Eachavatar, key) => (
                        <>
                          {

                            <AvatarBox
                              key={key}
                              user={user}
                              setactiveAvatar={setactiveAvatar}
                              id={Eachavatar.id}
                              img={Eachavatar.img}
                              title={Eachavatar.title}
                              level={Eachavatar.level}
                              type={Eachavatar.type}
                              lock={Eachavatar.lock}
                              price={Eachavatar.price}
                              coinRange={Eachavatar.coinRange}
                              userLevel={user.level}
                              userCoins={user.coins}
                              isCurrent={activeAvatar?.id === Eachavatar.id}
                            />

                          }
                        </>
                      ))
                    }
                  </div>


                )
              }



            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

