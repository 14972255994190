import React from 'react'
import activemenu from "../../assets/img/common/active-menu.svg"
import { Link } from 'react-router-dom'
import MenuTooltip from '../Tooltip'
import { useAuth } from '../../context/AuthContext'
export const Menu = ({ active }) => {
    const { minLoadingComplete } = useAuth();

    if(!minLoadingComplete){
      return ;

    }

  return (
    <nav className='bg-[#231F33] z-50 h-[70px] rounded-[12px] fixed bottom-[20px] w-[398px] xs:w-[95%] left-1/2 -translate-x-1/2 border-[1px] border-[#504C5C] flex items-center px-[12px]'>
      <Link to="/home" className={`flex flex-col items-center justify-center w-[74.8px] h-[46px] ${active == 1 && "border-[1px] border-[#4A4657] rounded-[6px]"}`}
        style={active == 1 ? { backgroundImage: `url(${activemenu})` } : {}}>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5533 16.4898V10.4898C11.5533 10.2909 11.4743 10.1002 11.3337 9.95951C11.193 9.81886 11.0023 9.73984 10.8033 9.73984H7.80334C7.60443 9.73984 7.41367 9.81886 7.27301 9.95951C7.13236 10.1002 7.05334 10.2909 7.05334 10.4898V16.4898M2.55334 8.23984C2.55329 8.02164 2.60085 7.80606 2.69269 7.60813C2.78453 7.4102 2.91844 7.23469 3.08509 7.09384L8.33509 2.59459C8.60583 2.36578 8.94886 2.24023 9.30334 2.24023C9.65783 2.24023 10.0009 2.36578 10.2716 2.59459L15.5216 7.09384C15.6882 7.23469 15.8222 7.4102 15.914 7.60813C16.0058 7.80606 16.0534 8.02164 16.0533 8.23984V14.9898C16.0533 15.3877 15.8953 15.7692 15.614 16.0505C15.3327 16.3318 14.9512 16.4898 14.5533 16.4898H4.05334C3.65552 16.4898 3.27399 16.3318 2.99268 16.0505C2.71138 15.7692 2.55334 15.3877 2.55334 14.9898V8.23984Z" stroke={`${active == 1 ? '#fff' : "#9A9BA0"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className={`text-[12px] text-[#9A9BA0] ${active == 1 && '!text-[#fff]'}`}>Home</p>
      </Link>
      <MenuTooltip message="Coming Soon">
        <Link to="/card" className={`flex flex-col items-center justify-center w-[74.8px] h-[46px] ${active == 2 && "border-[1px] border-[#4A4657] rounded-[6px]"}`}
          style={active == 2 ? { backgroundImage: `url(${activemenu})` } : {}}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2001_754)">
              <path d="M1.70007 8H16.7001M3.20007 4.25H15.2001C16.0285 4.25 16.7001 4.92157 16.7001 5.75V13.25C16.7001 14.0784 16.0285 14.75 15.2001 14.75H3.20007C2.37165 14.75 1.70007 14.0784 1.70007 13.25V5.75C1.70007 4.92157 2.37165 4.25 3.20007 4.25Z" stroke={`${active == 2 ? '#fff' : "#9A9BA0"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_2001_754">
                <rect width="18" height="18" fill="white" transform="translate(0.200073 0.5)" />
              </clipPath>
            </defs>
          </svg>

          <p className={`text-[12px] text-[#9A9BA0] ${active == 2 && '!text-[#fff]'}`}>Cards</p>
        </Link>

      </MenuTooltip>

      <Link to="/task" className={`flex flex-col items-center justify-center w-[74.8px] h-[46px] ${active == 3 && "border-[1px] border-[#4A4657] rounded-[6px]"}`}
        style={active == 3 ? { backgroundImage: `url(${activemenu})` } : {}}>
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 2V5M12 2V5M2.25 8H15.75M6.75 12.5L8.25 14L11.25 11M3.75 3.5H14.25C15.0784 3.5 15.75 4.17157 15.75 5V15.5C15.75 16.3284 15.0784 17 14.25 17H3.75C2.92157 17 2.25 16.3284 2.25 15.5V5C2.25 4.17157 2.92157 3.5 3.75 3.5Z" stroke={`${active == 3 ? '#fff' : "#9A9BA0"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


        <p className={`text-[12px] text-[#9A9BA0] ${active == 3 && '!text-[#fff]'}`}>Tasks</p>
      </Link>

      <Link to="/invite" className={`flex flex-col items-center justify-center w-[74.8px] h-[46px] ${active == 4 && "border-[1px] border-[#4A4657] rounded-[6px]"}`}
        style={active == 4 ? { backgroundImage: `url(${activemenu})` } : {}}>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2001_189)">
            <path d="M12.8 16.25V14.75C12.8 13.9544 12.484 13.1913 11.9214 12.6287C11.3588 12.0661 10.5957 11.75 9.80005 11.75H5.30005C4.5044 11.75 3.74134 12.0661 3.17873 12.6287C2.61612 13.1913 2.30005 13.9544 2.30005 14.75V16.25M15.05 6.5V11M17.3 8.75H12.8M10.55 5.75C10.55 7.40685 9.2069 8.75 7.55005 8.75C5.89319 8.75 4.55005 7.40685 4.55005 5.75C4.55005 4.09315 5.89319 2.75 7.55005 2.75C9.2069 2.75 10.55 4.09315 10.55 5.75Z" stroke={`${active == 4 ? '#fff' : "#9A9BA0"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2001_189">
              <rect width="18" height="18" fill="white" transform="translate(0.800049 0.5)" />
            </clipPath>
          </defs>
        </svg>



        <p className={`text-[12px] text-[#9A9BA0] ${active == 4 && '!text-[#fff]'}`}>Friends</p>
      </Link>
      <MenuTooltip message="Coming Soon">


        <Link to="/airdrop" className={`flex flex-col items-center justify-center w-[74.8px] h-[46px] ${active == 5 && "border-[1px] border-[#4A4657] rounded-[6px]"}`}
          style={active == 5 ? { backgroundImage: `url(${activemenu})` } : {}}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2377 15.7177C14.4673 14.9312 15.4087 13.7672 15.9209 12.4004C16.4332 11.0336 16.4885 9.53764 16.0788 8.13672C15.669 6.7358 14.8162 5.50545 13.6482 4.63013C12.4801 3.75481 11.0598 3.28168 9.60022 3.28168C8.1406 3.28168 6.72031 3.75481 5.55229 4.63013C4.38426 5.50545 3.53143 6.7358 3.12167 8.13672C2.7119 9.53764 2.76728 11.0336 3.2795 12.4004C3.79172 13.7672 4.73317 14.9312 5.96272 15.7177M6.60022 12.2827C6.18237 11.7256 5.92792 11.0631 5.86537 10.3695C5.80283 9.67589 5.93467 8.97857 6.24612 8.35567C6.55757 7.73277 7.03632 7.20891 7.62873 6.84278C8.22114 6.47665 8.9038 6.28272 9.60022 6.28272C10.2966 6.28272 10.9793 6.47665 11.5717 6.84278C12.1641 7.20891 12.6429 7.73277 12.9543 8.35567C13.2658 8.97857 13.3976 9.67589 13.3351 10.3695C13.2725 11.0631 13.0181 11.7256 12.6002 12.2827M10.3502 10.0327C10.3502 10.4469 10.0144 10.7827 9.60022 10.7827C9.18601 10.7827 8.85022 10.4469 8.85022 10.0327C8.85022 9.61851 9.18601 9.28272 9.60022 9.28272C10.0144 9.28272 10.3502 9.61851 10.3502 10.0327Z" stroke={`${active == 5 ? '#fff' : "#9A9BA0"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>




          <p className={`text-[12px] text-[#9A9BA0] ${active == 5 && '!text-[#fff]'}`}>Airdrop</p>
        </Link>
      </MenuTooltip>


    </nav>

  )
}
