import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReward } from '../../context/RewardContext';
import rewardIcon from '../../assets/img/task/reward.png';
import coinIcon from '../../assets/img/home/coin.svg';
import { useUserTasks } from '../../api/taskApi';

export const DailyRewardRec = ({ rewardData,loading }) => {
  
  const [formattedTime, setFormattedTime] = useState(null);

  // Helper function to format remaining time in HH:MM:SS format with padding
  const convertMillisecondsToTime = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  const secs = String(seconds % 60).padStart(2, '0');
  return { hours, minutes, seconds: secs };
  };

  useEffect(() => {
  if (rewardData && rewardData.remainingTime !== undefined && rewardData.requestTime !== undefined) {
    const intervalId = setInterval(() => {
    const utcNow = new Date().getTime();
    const elapsedTime = utcNow - rewardData.requestTime;
    const remaining = rewardData.remainingTime - elapsedTime;

    if (remaining <= 0) {
      clearInterval(intervalId);
      setFormattedTime({ hours: "00", minutes: "00", seconds: "00" });
    } else {
      setFormattedTime(convertMillisecondsToTime(remaining));
    }
    }, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }
  }, [rewardData]);

  return (
  <div className="border-[1px] flex items-center mt-4 border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2 mb-[24px]">
    <img src={rewardIcon} alt="Daily Reward" className="xs:w-[70px]" />
    <div className="flex-1 flex items-center justify-between">
    <div>
      <h1 className="text-[18px] font-semibold text-[#FFFFFF]">Daily Reward</h1>
      <p className="text-[16px] text-[#9A9BA0] font-normal">Each day brings you more coins</p>
    </div>
    <div className="flex items-center justify-center flex-col gap-1">
    
        <Link
        to="/daily-rewards"
        className="font-medium text-[12px] gap-2 text-[#fff] w-[60px] h-[30px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center"
        >
        Claim 
        </Link>
     
    </div>
    </div>
  </div>
  );
};
