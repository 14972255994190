import { Layout } from "../components/Layout";
import rollete from "../assets/img/spin/rollete.svg"
import rotater from "../assets/img/spin/rotater.svg"
import coin from "../assets/img/home/coin.svg"
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BuySpin } from "../components/spin/BuySpin";
import { claimSpin, getSpinToWin } from "../api/reward";
import { registerUser } from "../api/profile";
import { userdata } from "../utils/Function";

export const Spin = () => {
  const [buyspin,setbuyspin]=useState(false)
  const navigation=useNavigate()
  

  const [user,setuser]=useState({})


  useEffect(() => {
    userdata.username=window.localStorage.getItem('username')
    registerUser(userdata,setuser)

  }, []);

  const [remainingTime, setRemainingTime] = useState(null);
  const [formateTime, setFormateTime] = useState(null);
  const [RewardActive, setRewardActive] = useState(false);

  const ref=useRef(null)

  function convertMillisecondsToTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    return {
      hours,
      minutes,
      seconds: secs,
    };
  }
  
  
  useEffect(()=>{
  
    let intervalId;
    if(remainingTime!=null){



      
       intervalId = setInterval(() => {
        const nextRewardTime = new Date(remainingTime);
        const currentTime = new Date(Date.now());
  
        const differenceInMilliseconds = nextRewardTime - currentTime;
  
        // Check if current time is greater than or equal to next reward time
        if (differenceInMilliseconds <= 0) {
          clearInterval(intervalId); // Stop the interval
          setFormateTime({ hours: 0, minutes: 0, seconds: 0 }); // Optionally reset time
          setRewardActive(true)
          return;
        }
  
        const time = convertMillisecondsToTime(differenceInMilliseconds);
        setFormateTime(time);
  
      }, 1000);
  
      


      
    }
  
    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);

  },[remainingTime])


  useEffect(()=>{

    if(user.coins){
     
      getSpinToWin({
        username:user.username
      },setRemainingTime)
    }



  },[user])

  return (
    <Layout active={5} hideheaderbg={true}>

{
  buyspin&&(
<BuySpin setbuyspin={setbuyspin} />
  )
}
    
    
      <div className="mt-10">
        <div className="relative">
          <img src={rotater} className="mx-auto absolute top-0 z-20 left-1/2 -translate-x-1/2"  alt="" />
          <img src={rollete} ref={ref} className="mx-auto duration-1000"  alt="" />
        </div>
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center mt-5">Spin and Win</h1>
        <p className="text-[18px] text-[#9A9BA0] text-center">
        Spin and win more coins and you will get <br /> next free spin every 8 hours later!
        </p>

{
  RewardActive==false?(
    <button  className="font-bold text-[22px] text-[#fff] w-[50%] block mx-auto h-[52px] border-[1px] border-[#35333E]  blur-small-boxes rounded-[10px] my-[14px]">
      {formateTime?.hours}:{formateTime?.minutes}:{formateTime?.seconds}
    </button>
  ):(
    <button onClick={e=>{

      ref.current.classList.add('rotate-dynamic')

      setTimeout(() => {
      claimSpin({
        username:user.username,
        coins:"30000",
      },navigation)  
      }, 2000);
      
    }} className="font-bold text-[22px] text-[#fff] w-full h-[52px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px] my-[14px]">
      Spin Now
    </button>
  )
}
       
       <div className="flex items-start justify-between mt-[24px] mb-[32px]">
        <p className="text-[18px] text-[#fff]">Free spin active again 5:40:31</p>
        <Link to="/" className="text-[16px] font-normal text-[#FCD31F]">Get spins instant?</Link>
       </div>

       <div className="border-[1px] border-[#35333E]  blur-small-boxes rounded-[12px] h-[80px] relative px-[12px] flex items-center py-[12px] justify-between">

        <div>
          <h1 className="text-[21px] font-bold text-[#fff]">Buy 5 Spin</h1>
          <p className="flex items-center gap-1 text-[16px] text-[#9A9BA0] mt-[5px]"> 
          <img src={coin} className="w-[20px] h-[20px]" alt="" />
          100 Stars
          </p>
        </div>

        <button onClick={e=>{
          setbuyspin(true)
        }} className="flex items-center gap-[6px] w-[83px] bg-[#3D3E49] h-[29px] rounded-[8px] text-[#FFFFFF] text-[14px] justify-center mt-1 border-[1px] border-[#35333E]">
        Buy  Now
        </button>

           
        </div>

      </div>


     
    

      
    </Layout>
  )
}

