import React from 'react'
import bybit from "../../assets/img/card/bybit.svg"
import gift from "../../assets/img/card/gift.svg"
export const GiftCards = () => {
  return (
    <div className="grid grid-cols-3 gap-[12px] mt-[12px]">
        <div className="border-[1px] border-[#35333E]  blur-small-boxes rounded-[12px] h-[125px] flex items-center justify-center gap-1 relative flex-col">
            <img src={bybit} alt="" />
            <p className='text-[14px] text-[#fff]'>Bybit Listing</p>

        </div>
        <div className="border-[1px] border-[#35333E]  blur-small-boxes rounded-[12px] h-[125px] flex items-end relative">
            <img src={gift} alt="" />
            <span className="w-full absolute bottom-0 left-0 h-[60px] bg-gradient-to-b from-[#2f2a3831] to-[#312B39] block rounded-[12px] ">
            </span>
        </div>
        <div className="border-[1px] border-[#35333E]  blur-small-boxes rounded-[12px] h-[125px] flex items-end relative">
            <img src={gift} alt="" />
            <span className="w-full absolute bottom-0 left-0 h-[60px] bg-gradient-to-b from-[#2f2a3831] to-[#312B39] block rounded-[12px] ">
            </span>
        </div>
    </div>
  )
}
