import axiosInstance from "../hooks/axiosInstance";
import { useQuery } from "@tanstack/react-query";
const URL=process.env.REACT_APP_API_URL;

// Fetch referred users
 export const fetchReferredUsers = async (userId) => {
  const response = await axiosInstance.get(`${URL}/api/referred-users`);
  return response.data.referredUsers;
};

export const useReferredUsers = (userId) => {
  return useQuery({
    queryKey: ["referredUsers", userId],
    queryFn: () => fetchReferredUsers(userId),
    enabled: !!userId, // Only run query if userId is defined
    staleTime: 60000, 

  });
};
