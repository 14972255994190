import { Layout } from "./components/Layout";
import { SmallBox } from "./components/home/SmallBox";
import dailyReward from "./assets/img/home/daily-reward.svg"
import combo from "./assets/img/home/combo.svg"
import spin from "./assets/img/home/spin.svg"
import coin from "./assets/img/home/coin.png"
import { debounce } from 'lodash';


import { UpdateArea } from "./components/home/UpdateArea";
import { UpdateAvatar } from "./components/home/UpdateAvatar";
import { useEffect, useRef, useState } from "react";
import {  useUpdateCoins } from "./api/profile";
import { SmallDailyReward } from "./components/home/SmallDailyReward";
import { DailyComboBox } from "./components/home/DailyComboBox";
import { SpinToWinBox } from "./components/home/SpinToWinBox";
import { useAuth } from "./context/AuthContext";
import { useLevel } from "./context/LevelContext";
import { useEnergy } from "./context/EnergyContext";
import { hapticFeedback, isHapticFeedbackSupported } from '@telegram-apps/sdk'; 

export const Home = () => {
  const { user, login,coinstate,addCoins } = useAuth();
  console.log('uu', user);
  const refNumber = useRef(null)
  const { getLevelDetails } = useLevel(); // Fetch level configurations
const { energyStatus, decreaseEnergy } = useEnergy();

  
  const increaseCoinCount = (event, decide) => {
    const userLevelDetails = getLevelDetails(user.level);
    console.log('userLevelDetails', userLevelDetails);
      console.log('yyyyy', decide);
   
    if (decide && userLevelDetails) {
      
      const coinEarned = userLevelDetails.coinPerTap;
  
       addCoins(coinEarned);
       

       decreaseEnergy(coinEarned);
      // Trigger vibration or fallback shake effect

    
      //const touch = event?.changedTouches[0];
      // const x = touch?.clientX;
      // const y = touch?.clientY;
      const x = event.clientX;
      const y = event.clientY;
      refNumber.current.insertAdjacentHTML(
        'beforeend',
        `<li style="left:${x}px;top:${y}px" class="fixed text-[#fff] font-bold text-[88px] z-50  pointer-events-none translate-x-[-40%] duration-1000">+${coinEarned}</li>`
      );

      const listItems = refNumber.current.querySelectorAll('li');
      const lastItem = listItems[listItems.length - 1];

      setTimeout(() => {
        lastItem.classList.add('animate_one');
        lastItem.style.top = `${y - 100}px`;
      }, 100);
    }
  };

 if(!user) return null;


  return (
    <Layout active={1} hideheaderbg={true}>
      <UpdateArea user={user} />


      <div className="grid grid-cols-3 gap-[12px] mt-[20px] mb-[15px]">
        <SmallDailyReward type="Daily Reward" img={dailyReward} user={user} />
        <DailyComboBox type="Daily combo" img={combo} imgstyle="-translate-y-[40px]" user={user} />
        <SpinToWinBox type="Games" img={spin} imgstyle="-translate-y-[40px]" user={user} />
      </div>

      <ul ref={refNumber}>

      </ul>

      <div className="flex items-center justify-center gap-[6px]">
        <img src={coin} className="h-[35px]" loading="lazy" alt="" />
        <h1 className="text-[#fff] font-extra-bold text-[32px]">{coinstate.toLocaleString()}</h1>
      </div>

      <UpdateAvatar user={user} onclick={increaseCoinCount} />
    </Layout>
  )
}

