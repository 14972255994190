const BASIC_ASSET_URL = "https://res.cloudinary.com/dxa69h0il/image/upload/v1730194436/twinkle/avatars/basic";
const PREMIUM_ASSET_URL = "https://res.cloudinary.com/dxa69h0il/image/upload/v1730226824/twinkle/avatars/premium";
const EPIC_ASSET_URL = "https://res.cloudinary.com/dxa69h0il/image/upload/v1730226924/twinkle/avatars/epic";

const avatars = [
  { id: "RoyalRadiance", title: "Royal Radiance", img: `${BASIC_ASSET_URL}/1-RoyalRadiance.png`, level: 1, price: 0, coinRange: "0-100k", type: "basic" },
  { id: "ZenGlow", title: "Zen Glow", img: `${BASIC_ASSET_URL}/2-ZenGlow.png`, level: 2, price: 0, coinRange: "100k-500k", type: "basic" },
  { id: "ChicShine", title: "Chic Shine", img: `${BASIC_ASSET_URL}/3-ChicShine.png`, level: 3, price: 0, coinRange: "500k-2M", type: "basic" },
  { id: "HarmonySpark", title: "Harmony Spark", img: `${BASIC_ASSET_URL}/4-HarmonySpark.png`, level: 4, price: 0, coinRange: "2M-10M", type: "basic" },
  { id: "FuryFlash", title: "Fury Flash", img: `${BASIC_ASSET_URL}/5-FuryFlash.png`, level: 5, price: 0, coinRange: "10M-50M", type: "basic" },
  { id: "WarriorWisp", title: "Warrior Wisp", img: `${BASIC_ASSET_URL}/6-Warrior_Wisp.png`, level: 6, price: 0, coinRange: "50M-100M", type: "basic" },
  { id: "BrawlerBlaze", title: "Brawler Blaze", img: `${BASIC_ASSET_URL}/7-BrawlerBlaze.png`, level: 7, price: 0, coinRange: "100M-500M", type: "basic" },
  { id: "ChampionGlow", title: "Champion Glow", img: `${BASIC_ASSET_URL}/8-ChampionGlow.png`, level: 8, price: 0, coinRange: "500M-1B", type: "basic" },
  { id: "CosmicTwinkle", title: "Cosmic Twinkle", img: `${BASIC_ASSET_URL}/9-CosmicTwinkle.png`, level: 9, price: 0, coinRange: "1B-10B", type: "basic" },
  { id: "KingSparkle", title: "King Sparkle", img: `${BASIC_ASSET_URL}/10-KingSparkle.png`, level: 10, price: 0, coinRange: "10B-20B", type: "basic" },
  { id: "CombatComet", title: "Combat Comet", img: `${PREMIUM_ASSET_URL}/CombatComet.png`, price: 100, coinRange: null, type: "premium" },
  { id: "FitFlicker", title: "Fit Flicker", img: `${PREMIUM_ASSET_URL}/FitFlicker.png`, price: 100, coinRange: null, type: "premium" },
  { id: "GalacticGleam", title: "Galactic Gleam", img: `${PREMIUM_ASSET_URL}/GalacticGleam.png`, price: 150, coinRange: null, type: "premium" },
  { id: "HoopHero", title: "Hoop Hero", img: `${PREMIUM_ASSET_URL}/HoopHero.png`, price: 150, coinRange: null, type: "premium" },
  { id: "OfficerGlimmer", title: "Officer Glimmer", img: `${PREMIUM_ASSET_URL}/OfficerGlimmer.png`, price: 250, coinRange: null, type: "premium" },
  { id: "PrincessTwinkle", title: "Princess Twinkle", img: `${PREMIUM_ASSET_URL}/PrincessTwinkle.png`, price: 250, coinRange: null, type: "premium" },
  { id: "TwilightTitan", title: "Twilight Titan", img: `${PREMIUM_ASSET_URL}/TwilightTitan.png`, price: 500, coinRange: null, type: "premium" },
  { id: "VortexViper", title: "Vortex Viper", img: `${PREMIUM_ASSET_URL}/VortexViper.png`, price: 500, coinRange: null, type: "premium" },
  { id: "DetectiveTwinkle", title: "Detective Twinkle", img: `${EPIC_ASSET_URL}/DetectiveTwinkle.png`, price: 1000, coinRange: null, type: "epic" },
  { id: "PavelDurov", title: "Pavel Durov", img: `${EPIC_ASSET_URL}/PavelDurov.png`, price: 1000, coinRange: null, type: "epic" },
];

export default avatars;
