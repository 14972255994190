import { Layout } from "../components/Layout";

import gift from "../assets/img/invite/gift.png";
import giftPremium from "../assets/img/invite/gift-premium.png";
import coin from "../assets/img/home/coin.png";
import bginvite from '../assets/img/invite/friendavatar.png';
import plususer from '../assets/img/invite/plus-user.png';
import link from '../assets/img/invite/link.png';

import { useEffect, useRef, useState } from "react";

import { useAuth } from "../context/AuthContext";
import { useReferredUsers } from "../api/referrals";
import LevelBonusModal from "../components/modals/LevelBonusModal";
import { Check } from "lucide-react";

export const Invite = () => {
  const { user } = useAuth();
  const botUrl = process.env.REACT_APP_TELEGRAM_BOT_URL; // Bot URL from env
  const { data: referredUsers, isLoading, isFetching, refetch, error } = useReferredUsers(user?._id);
  const [showLevelBonus, setShowLevelBonus] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  // Function to copy URL to clipboard
  function copyURL() {
    const userId = user._id;
    const inviteLink = `${botUrl}?start=${userId}`;
    navigator.clipboard.writeText(inviteLink)
      .then(() => {
        setShowTooltip(true);
        // Hide tooltip after 2 seconds
        setTimeout(() => setShowTooltip(false), 2000);
      })
      .catch(err => {
        console.error("Failed to copy the link:", err);
      });
  }

  // Function to share via Telegram
  function shareToTelegram() {
    const userId = user._id;
    const inviteLink = `${botUrl}?start=${userId}`;
    const message = `👑 Join me in @Twinkle game and earn $TWKL token soon!
⭐️ 5,000 Twinkle coin bonus for you.
⭐️ 25,000 Twinkle coin bonus if you are Premium.`;
    // Open Telegram app with the invite link
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(message)}`;
    window.open(telegramUrl, '_blank');
  }
  useEffect(() => {
    if (showTooltip) {
      const tooltip = tooltipRef.current;
      const rect = tooltip.getBoundingClientRect();
      if (rect.right > window.innerWidth) {
        tooltip.classList.add('right-0');
        tooltip.classList.remove('-translate-x-1/2', 'left-1/2');
      } else {
        tooltip.classList.add('left-1/2', '-translate-x-1/2');
        tooltip.classList.remove('right-0');
      }
    }
  }, [showTooltip]);
  return (
    <Layout active={4} hideheaderbg={true}>
      {/* <UpdateArea user={user} /> */}
        <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[150px]" />

      <div className="">
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center pt-5">Invite Friends!</h1>
        <p className="text-[18px] font-normal text-[#9A9BA0] text-center">You and your friend will receive bonuses!</p>
      </div>

      <div className="flex flex-col gap-[12px] my-[24px]">
        <div onClick={shareToTelegram} className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={gift} alt="" />
          <div>
            <h1 className="text-[18px] font-semibold text-[#FFFFFF]">Invite a friend</h1>
            <div className="flex items-center gap-[2px]">
              <img src={coin} className="w-[17.2px] h-[17.2px]" alt="" />
              <p className="text-[16px] text-[#9A9BA0] font-normal">
                <span className="font-semibold text-[#F4BE0F]">+5,000</span> for you and your friend
              </p>
            </div>
          </div>
        </div>
        <div onClick={shareToTelegram} className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={giftPremium} alt="" />
          <div>
            <h1 className="text-[18px] font-semibold text-[#FFFFFF]">Invite a friend with Telegram Premium</h1>
            <div className="flex items-center gap-[2px]">
              <img src={coin} className="w-[17.2px] h-[17.2px]" alt="" />
              <p className="text-[16px] text-[#9A9BA0] font-normal">
                <span className="font-semibold text-[#F4BE0F]">+25,000</span> for you and your friend
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="px-4 pt-[6px] border-[1px] mb-[24px] border-[#35333E] rounded-[12px] bg-no-repeat bg-right-bottom bg-contain h-[143px]"
        style={{
          backgroundImage: `url(${bginvite})`,
          backgroundSize: '25%',
        }}
      >
        <h1 className="text-[32px] font-bold text-[#fff] flex items-center gap-1">
          <img src={coin} className="w-[36.18px] h-[36.18px]" alt="" />
          +50 Trillion
        </h1>
        <p className="text-[18px] mt-[0px] mb-[8px] text-[#9A9BA0]">When your friend levels up.</p>
        <button onClick={() => setShowLevelBonus(true)} className="flex items-center gap-2 w-[179px] h-[42px] text-[18px] font-normal text-[#fff] blur-small-boxes justify-center rounded-[12px]">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.42041 13.875L12.0171 9.27832L7.42041 4.68164" stroke="white" strokeWidth="1.53223" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Learn More
        </button>
      </div>

      <div className="mb-20">
        <div className="flex items-center justify-between mb-[20px]">
          <h1 className="flex items-center gap-1 text-[18px] font-bold text-[#FFFFFF]">List of your friends</h1>
          <button onClick={refetch}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.71875 10.7783C2.71875 8.7892 3.50893 6.88154 4.91545 5.47502C6.32197 4.0685 8.22963 3.27832 10.2187 3.27832C12.3155 3.28621 14.3279 4.10434 15.8354 5.56165L17.7187 7.44499M17.7187 7.44499V3.27832M17.7187 7.44499H13.5521M17.7187 10.7783C17.7187 12.7674 16.9286 14.6751 15.522 16.0816C14.1155 17.4881 12.2079 18.2783 10.2187 18.2783C8.12204 18.2704 6.10956 17.4523 4.60208 15.995L2.71875 14.1117M2.71875 14.1117H6.88542M2.71875 14.1117V18.2783" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>

        <ul>
          {isLoading || isFetching ? (
            <li className="text-[#9A9BA0]">Loading friends...</li>
          ) : error ? (
            <li className="text-[#FF6B6B]">Failed to load friends</li>
          ) : referredUsers && referredUsers.length > 0 ? (
            referredUsers.map((friend) => (
              <li key={friend._id} className="flex items-center gap-2 mb-2 w-full h-[66px] text-[18px] font-semibold text-[#fff] blur-small-boxes pl-4 rounded-[12px]">
                {friend.profileImg ? (
                  <img
                    src={friend.profileImg}
                    alt={friend.username}
                    className="w-[40px] h-[40px] rounded-full"
                  />
                ) : (
                  <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#6B7280] text-[#fff] font-bold">
                    {friend.firstName?.charAt(0)}
                    {friend.lastName?.charAt(0)}
                  </div>
                )}
                <p>{friend.username || `${friend.firstName} ${friend.lastName}`}</p>
              </li>
              
            ))
            
          ) : (
            <li className="text-[#9A9BA0]">You haven’t invited anyone yet!</li>
          )}
        </ul>
        {/* fixed button */}
        <div className="grid grid-cols-[1fr_52px] gap-2 fixed bottom-[110px] w-[398px] xs:w-[95%] left-1/2 -translate-x-1/2">
          <button onClick={shareToTelegram} className="flex items-center gap-3 justify-center bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[8px] h-[52px] font-bold text-[22px] text-[#fff]">
            <img src={plususer} alt="" />
            Invite a friend
          </button>
          <div className="relative">
            <button onClick={copyURL} className="w-full h-[52px] rounded-[12px] border-[1px] border-[#35333E] flex items-center justify-center blur-small-boxes">
              <img src={link} alt="" />
            </button>

            {showTooltip && (
              <div
                ref={tooltipRef}
                className="absolute bottom-full mb-2 px-4 py-2 rounded-full bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] text-white text-sm flex items-center gap-1 z-10"
              >
                <div className="bg-white rounded-full p-1 flex items-center justify-center">
                  <Check size={12} strokeWidth={3} color="black" /> {/* Adjust icon size and color */}
                </div>
                Copied!
              </div>
            )}
          </div>
        </div>

      </div>
      {showLevelBonus && (
        <LevelBonusModal onClose={() => setShowLevelBonus(false)} />
      )}
    </Layout>
  );
};
