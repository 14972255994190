import React from 'react'

export const Badge = ({id,image,text}) => {
  return (
    <div className="card-label">
        <input type="radio" name="card-btn" id={id} className="hidden" />
        <label htmlFor={id} className="text-[16px] text-[#9A9BA0] flex items-center justify-center gap-[6px] px-[16px] border-[1px] border-[#35333E]  blur-small-boxes h-[35px] rounded-[8px] cursor-pointer ">
        <img src={image} alt="" />
        {text}
        </label>
    </div>
  )
}
