import React, { useEffect, useState } from 'react'
import { claimComboReward, getDailyCombo } from '../../api/reward';

export const ComboTime = ({user,setuser}) => {



  const [remainingTime, setRemainingTime] = useState(null);
  const [formateTime, setFormateTime] = useState(null);
  const [available, setavailable] = useState(false);
  


  function convertMillisecondsToTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    return {
      hours,
      minutes,
      seconds: secs,
    };
  }
  
  
  useEffect(()=>{
  
    let intervalId;
    if(remainingTime!=null){



      
       intervalId = setInterval(() => {
        const nextRewardTime = new Date(remainingTime);
        const currentTime = new Date(Date.now());
  
        const differenceInMilliseconds = nextRewardTime - currentTime;
  
        // Check if current time is greater than or equal to next reward time
        if (differenceInMilliseconds <= 0) {
          clearInterval(intervalId); // Stop the interval
          setFormateTime({ hours: 0, minutes: 0, seconds: 0 }); // Optionally reset time
          setavailable(true)
          return;
        }
  
        const time = convertMillisecondsToTime(differenceInMilliseconds);
        setFormateTime(time);
  
      }, 1000);
  
      


      
    }
  
    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);

  },[remainingTime])


  useEffect(()=>{

    if(user.coins){
     
      getDailyCombo({
        username:user.username
      },setRemainingTime)
    }



  },[user])






  return (
    <>
    {available==false?(
      <div className="flex items-center justify-between">
      <p className="text-[18px] text-[#9A9BA0]">{formateTime?.hours}:{formateTime?.minutes}:{formateTime?.seconds}</p>
      <p className="text-[18px] text-[#9A9BA0]">
      Find 3 combo cards after this time
      </p>
  </div>
    ):(
      <div className="flex items-center justify-between">
   <button onClick={e=>{
    claimComboReward({
      username:user.username,
      coins:75000
    },setuser,setavailable)
   }} className="font-medium text-[12px] gap-2 text-[#fff] w-[95px] h-[30px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center">
                          
        Claim Reward
    </button>
    <p className="text-[18px] text-[#9A9BA0]">
    Claim the Daily Combo
    </p>
</div>
    )}
    </>
  )
}
