import { Layout } from "../components/Layout";
import { UpdateArea } from "../components/home/UpdateArea";
import badge from "../assets/img/airdrop/badge.svg"
import main from "../assets/img/airdrop/main.svg"
import income from "../assets/img/airdrop/income.svg"
import info from "../assets/img/airdrop/info.svg"
import friends from '../assets/img/airdrop/friends.svg'
import arrow from '../assets/img/airdrop/arrow.svg'
import okx from '../assets/img/airdrop/okx.svg'
import bybt from '../assets/img/airdrop/bybt.svg'
import ton from '../assets/img/airdrop/ton.svg'
import earn from '../assets/img/airdrop/earn.svg'
import passive from '../assets/img/airdrop/passive.svg'
import close from '../assets/img/airdrop/close.svg'
import { useEffect, useState } from "react";
import { registerUser } from "../api/profile";
import { userdata } from "../utils/Function";


export const AirDrop = () => {
  const [activelink,setactivelink]=useState(1)
  const [infostate,setinfo]=useState(false)




  const [user,setuser]=useState({})


  useEffect(() => {
    userdata.username=window.localStorage.getItem('username')
    registerUser(userdata,setuser)

  }, []);


  return (
    <Layout active={5} hideheaderbg={true}>
      <UpdateArea user={user} />

{
  infostate&&(

  
      <div className="w-[430px] xs:w-full overflow-hidden h-[587px] bg-[#0C0E1A] border-t-[1px] border-t-[#3E4051] rounded-tl-[16px] rounded-tr-[16px] fixed bottom-0 left-1/2 -translate-x-1/2 z-50 flex flex-col">
<div className="px-[16px] py-[12px] flex items-center justify-between">
  <h1 className="text-[20px] font-bold text-[#FFFFFF]">Passive income</h1>
  <img src={close} className="cursor-pointer"  onClick={e=>{
            setinfo(false)
           }} alt="" />
</div>
<div className="px-4 flex flex-col items-center text-center z-10  flex-1 pt-4">
  <img src={passive} alt="" />
  <h1 className="text-[36px] text-[#FFFFFF] font-bold">Passive income</h1>
  <p className="flex items-center justify-center mt-[7px] gap-[6px] text-[29px] font-bold text-[#FFFFFF]">
          <img src={badge} alt="" />
        $TWKL
        </p>
  <p className="font-normal text-[18px] text-[#FFFFFF] mt-[24px]">Earn passive income in the game by 
    purchasing cards, which will generate profit per hour and earn game coins even while you're offline.</p>

    <div className="w-full flex-1 pb-8 flex items-end">
    <button className="font-bold text-[22px] text-[#fff] w-full min-w-full h-[52px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px]">
    Close
    </button>
    </div>
</div>

<span className='w-[248px] h-[248px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full absolute left-1/2 -translate-x-1/2 top-[200px] blur-[150px]'></span>

      </div>
)
}
      <div className="">
        <img src={main} alt="" className="mx-auto" />
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center mt-5">AirDrop</h1>
        <div className="my-[12px] mb-[8px] flex items-center gap-2">
        <span className="flex-1 bg-[#534F59] h-[1px] block"></span>
        <p className="text-[16px] font-normal text-[#9A9BA0] text-center">Listing date</p>
        <span className="flex-1 bg-[#534F59] h-[1px] block"></span>
        </div>
        <p className="text-[32px] font-extra-bold text-[#F4BE0F] text-center">Announced soon</p>
        <span className="flex-1 bg-[#534F59] h-[1px] block mt-[12px]"></span>
      </div>


     
     <div className="flex flex-col gap-[12px] my-[24px]">
     <div className="border-[1px] flex flex-col items-center  border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2 ">
        <h1 className="text-[18px] font-normal text-[#fff]">AirDrop allocation points</h1>
        <p className="flex items-center justify-center gap-[6px] text-[28px] font-bold text-[#FFFFFF]">
          <img src={badge} alt="" />
        $TWKL
        </p>
      </div>


      <div className="grid grid-cols-2 gap-[12px] p-2 bg-[#241E31] rounded-[12px]">
        <button onClick={()=>{
          setactivelink(1)
        }} className={`w-full h-[42px] text-[18px] text-[#9A9BA0]  items-center  rounded-[8px] ${activelink==1&&'text-[#FFFFFF] border-[1px] border-[#35333E]  blur-small-boxes'}`}>
        Points
        </button>
        <button  onClick={()=>{
          setactivelink(2)
        }}  className={`w-full h-[42px] text-[18px] text-[#9A9BA0]  items-center  rounded-[8px] ${activelink==2&&'text-[#FFFFFF] border-[1px] border-[#35333E]  blur-small-boxes'}`}>
        Withdrawal
        </button>
      </div>
      {
        activelink==1&&(
          <>
            <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={income} alt="" />
          <div className="flex flex-1 items-center justify-between">
            
            
           <div >

           <p className="text-[16px] text-[#9A9BA0] font-normal">  
            Passive income</p>
            
            <p className="flex items-center  gap-[3px] text-[16px] font-bold text-[#FFFFFF]">
          <img src={badge}  className="w-[20px]" alt="" />
        $TWKL
        </p>
           </div>

           <img src={info} className="cursor-pointer" alt="" onClick={e=>{
            setinfo(true)
           }} />
          </div>
      </div>
      <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={earn} alt="" />
          <div className="flex flex-1 items-center justify-between">
           <div>
           <p className="text-[16px] text-[#9A9BA0] font-normal">  
           Earn tasks
           </p>
            
            <p className="flex items-center  gap-[3px] text-[16px] font-bold text-[#FFFFFF]">
          <img src={badge}  className="w-[20px]" alt="" />
        $TWKL
        </p>
           </div>

           <img src={info} alt="" />
          </div>
      </div>

      <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={friends} alt="" />
          <div className="flex flex-1 items-center justify-between">
           <div>
           <p className="text-[16px] text-[#9A9BA0] font-normal">  
           Friends
           </p>
            
            <p className="flex items-center  gap-[3px] text-[16px] font-bold text-[#FFFFFF]">
          <img src={badge}  className="w-[20px]" alt="" />
        $TWKL
        </p>
           </div>

           <img src={info} alt="" />
          </div>
      </div>
          </>
        )
      }


{
        activelink==2&&(
          <>
            <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={okx} alt="" />
          <div className="flex flex-1 items-center justify-between">
            
            <h1 className="text-[18px] font-semibold text-[#fff]">OKX early birds</h1>

           <img src={arrow} alt="" />
          </div>
      </div>
      <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={bybt} alt="" />
          <div className="flex flex-1 items-center justify-between">
            
            <h1 className="text-[18px] font-semibold text-[#fff]">Bybit early birds</h1>

           <img src={arrow} alt="" />
          </div>
      </div>
      <div className="border-[1px] flex items-center border-[#35333E]  blur-small-boxes p-2 rounded-[12px] gap-2">
        <img src={ton} alt="" />
          <div className="flex flex-1 items-center justify-between">
            
            <h1 className="text-[18px] font-semibold text-[#fff]">Connect your TON wallet</h1>

           <img src={arrow} alt="" />
          </div>
      </div>
          </>
        )
      }

    

     </div>

     

      
    </Layout>
  )
}

