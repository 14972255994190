import React, { useRef, useState, useEffect } from 'react';
import current from "../../assets/img/home/current.png";
import rocket from "../../assets/img/home/rocket.png";
import spider from "../../assets/img/home/spider.svg";
import gradient from "../../assets/img/home/gredient.svg";
import { useNavigate } from 'react-router-dom';
import avatars from '../../configs/avatarsConfig';
import { useEnergy } from '../../context/EnergyContext';
import { hapticFeedback, isHapticFeedbackSupported } from '@telegram-apps/sdk';

export const UpdateAvatar = ({ user, onclick }) => {
  const { energyStatus, refillEnergy,stopRefilling } = useEnergy(); // Use refillEnergy from context
  const navigate = useNavigate();
  const currentAvatar = avatars.find((avatar) => avatar.id === user?.currentAvatar);
  const lastTouchTime = useRef(0);
 
  // const [isBouncing, setIsBouncing] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(""); // State to store the cached image URL

  useEffect(() => {
    if (currentAvatar?.img) {
      const img = new Image();
      img.src = currentAvatar.img;
      img.onload = () => {
        setAvatarUrl(currentAvatar.img); 
      };
    }
  }, [currentAvatar?.img]);

  useEffect(() => {
    const element = document.getElementById("avatar-image");
    if (element) {
      void element.offsetWidth; // Trigger a reflow to stabilize layout
    }
  }, []);


  // Handle clicks on the overlay and avatar itself
  const handleOverlayClick = (event) => {

    if (isHapticFeedbackSupported()) {
      hapticFeedback.impactOccurred('medium');
    } else {
      if (navigator.vibrate) {
        navigator.vibrate(100);
      }
    }

    // const avatarElement = document.getElementById("avatar-image");
    // if (avatarElement) {
    //   avatarElement.classList.remove("animate-bounce-custom"); // Remove animation class
    //   void avatarElement.offsetWidth; // Force reflow for immediate re-application
    //   avatarElement.classList.add("animate-bounce-custom"); // Re-apply animation class
    // }

    const now = Date.now();
    if (now - lastTouchTime.current < 50) return; // Debounce multiple touches
    lastTouchTime.current = now;
    // setIsBouncing(false);
    // requestAnimationFrame(() => {
    //   setIsBouncing(true); // Trigger animation reflow
    // });

    //console.log('onclick', onclick);
    onclick(event, true);

    // setTimeout(() => setIsBouncing(false), 150);
  };

  return (
    <div
      style={{ backgroundImage: `url(${spider})` }}
      className="bg-gradient-to-b from-[#1B152A] to-[#15161B] w-full mt-2 rounded-[23px] py-4 pb-6 bg-no-repeat relative overflow-hidden"
    >
      {/* Clickable Overlay Area */}
      <div
        onClick={handleOverlayClick}
        onContextMenu={(e) => e.preventDefault()}
        className={`absolute top-0 left-0 w-full  z-10 cursor-pointer `} // Covers top area including avatar
      ></div>

      {/* Gradient Background */}
      <img src={gradient} alt="" className="absolute top-0" />

      {/* Avatar Image - Apply onClick Directly */}
      <div className="relative mb-4">
        <div className={`avatar-container justify-center`} onClick={handleOverlayClick}>
          <img
            id="avatar-image"
            src={avatarUrl || currentAvatar?.img} // Use cached URL if available
            className="mx-auto w-[200px] cursor-pointer relative z-20"
            alt="Current Avatar"
          />

        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="33"
          viewBox="0 0 190 33"
          fill="none"
          className="absolute left-1/2 transform -translate-x-1/2 bottom-[-15px]"
        >
          <ellipse cx="95.2249" cy="16.8398" rx="94.7344" ry="15.8848" fill="#D9D9D9" fill-opacity="0.08" />
        </svg>
      </div>
      {/* Status and Boost Button */}
      <div className="flex items-center justify-between px-4 mb-[10px] relative z-20">
        <div className="flex items-center gap-1 text-[16px] font-bold text-[#fff]">
          <img src={current} alt="" />
          {energyStatus?.current} / {energyStatus?.max}
        </div>
        <button
          onClick={() => navigate("/boost")}
          className="flex items-center gap-1 text-[16px] font-bold text-[#fff] z-20 pointer-events-auto bg-purple-600 bg-opacity-20 rounded-full px-4 py-1"
        >
          <img className='h-5' src={rocket} alt="" />
          Boost
        </button>
      </div>

      {/* Customize Button */}
      <div className="flex items-center justify-center flex-col gap-[8px] relative z-20">
        <button
          onClick={() => navigate("/update-avatar")}
          className="font-bold text-[18px] text-[#fff] w-[250px] h-[47.59px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px] whitespace-nowrap pointer-events-auto"
        >
          Customize appearance
        </button>
        <p className="text-[14px] text-[#9A9BA0]">Upgrade your avatar and get more coins!</p>
      </div>
    </div>
  );
};
