import React, { useEffect, useState } from 'react';
import lockBtn from "../../assets/img/avatar/lock.svg";
import spider from "../../assets/img/home/spider.svg";
import gradient from "../../assets/img/home/gredient.svg";
import { usePurchaseAvatar, useChooseAvatar } from '../../api/profile';
import { useAuth } from '../../context/AuthContext';

function formatNumber(num) {
  if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
  if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
  return num.toString();
}

export const CurrentAvatar = ({ activeAvatar }) => {
  const { user, updateAvatar } = useAuth();
  const [isAvatarUpdating, setIsAvatarUpdating] = useState(false); // State for immediate UI feedback

  const ownedAvatars = user.purchasedAvatars || [];
  const isOwned = ownedAvatars.includes(activeAvatar?.id);
  const canUse = user.level >= activeAvatar?.level || isOwned;
  const canPurchase = Number(user.coins) >= activeAvatar?.price;

  const { mutate: purchaseAvatar, isLoading: isPurchasing } = usePurchaseAvatar();
  const { mutate: chooseAvatar, status: chooseAvatarStatus } = useChooseAvatar();

  // Update UI feedback state based on user data and selected avatar
  useEffect(() => {
    if (isAvatarUpdating && user.currentAvatar === activeAvatar.id) {
      setIsAvatarUpdating(false); // Stop updating state once the backend update is reflected
    }
  }, [user.currentAvatar, activeAvatar.id, isAvatarUpdating]);

const handleSelectAvatar = () => {
  // Optimistically update the avatar state for immediate feedback
  updateAvatar(activeAvatar.id); // This updates the context state immediately
  setIsAvatarUpdating(true); // Trigger loading UI feedback
  
  // Proceed with backend request to update avatar
  chooseAvatar(
    { avatarId: activeAvatar.id },
    {
      onSuccess: () => {
        // Successful update, so just confirm the UI change
        setIsAvatarUpdating(false);
      },
      onError: () => {
        // Error occurred, so revert to the previous avatar
        updateAvatar(user.currentAvatar);
        setIsAvatarUpdating(false);
      },
    }
  );
};
  const handlePurchase = () => {
    const tg = window.Telegram.WebApp;
    tg.showInvoice({
      title: `Unlock ${activeAvatar.title}`,
      description: `Purchase ${activeAvatar.title} avatar using Stars.`,
      payload: JSON.stringify({ avatarId: activeAvatar.id }),
      provider_token: process.env.REACT_APP_PROVIDER_TOKEN,
      currency: 'XTR',
      prices: [{ label: activeAvatar.title, amount: activeAvatar.price * 100 }],
      max_tip_amount: 0,
    });

    tg.onEvent('invoice_closed', () => {
      console.log('Payment dialog closed by user.');
    });

    tg.onEvent('payment_completed', () => {
      console.log('Payment successful!');
      updateAvatar(activeAvatar.id); // Update user avatar in context
    });
  };

  return (
    <div
      style={{ backgroundImage: `url(${spider})` }}
      className="bg-gradient-to-b from-[#1B152A] to-[#15161B] w-full mt-3 rounded-[23px] py-4 px-4 pb-6 bg-no-repeat relative overflow-hidden grid grid-cols-[1fr_182px] items-center"
    >
      <img src={gradient} alt="" className="absolute top-0" />
      <img src={activeAvatar?.img} alt="" className="mx-auto mb-2 relative z-10 w-[150px]" />

      <div>
        <div className="flex flex-col items-start relative z-10">
          <h1 className="text-[18px] font-bold text-[#fff]">{activeAvatar?.title}</h1>
          <p className="text-[14px] font-normal text-[#BEBFC3]">
            Breaking into the business world on the crest of a wave and not afraid to swim against the current!
          </p>
        </div>

        {/* UI Feedback for Avatar Selection */}
        {activeAvatar.id === user.currentAvatar || isAvatarUpdating ? (
          <div className="mt-4 relative z-10">
            <p className="text-[16px] text-[#fff]">You are using this skin currently</p>
          </div>
        ) : activeAvatar.type === "basic" && canUse ? (
          <div className="mt-4 relative z-10">
            <p className="text-[16px] text-[#fff]">You can use this skin now!</p>
            <button
              onClick={handleSelectAvatar}
              className={`font-medium text-[18px] text-[#fff] w-full h-[40px] rounded-[8px] bg-[#ffffff33] border-[1px] border-[#ffffff8f] mt-2 ${
                chooseAvatarStatus === 'pending' ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              {chooseAvatarStatus === 'pending' ? 'Applying...' : 'Use this Skin'}
            </button>
          </div>
        ) : activeAvatar.type === "basic" ? (
          <div className="mt-4 relative z-10">
            <p className="text-[16px] text-[#fff]">
              Reach level {activeAvatar.level} to unlock this skin.
            </p>
          </div>
        ) : !isOwned ? (
          <div className="mt-4 relative z-10">
            <p className="text-[16px] text-[#fff]">
              Purchase this skin for {formatNumber(activeAvatar.price)} Stars!
            </p>
            <button
              onClick={handlePurchase}
              className={`font-medium text-[18px] gap-2 text-[#fff] w-full h-[40px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-2 flex items-center justify-center ${
                isPurchasing ? 'opacity-40 pointer-events-none' : ''
              }`}
            >
              <img src={lockBtn} alt="" />
              {isPurchasing ? 'Purchasing...' : 'Unlock Skin'}
            </button>
          </div>
        ) : (
          <div className="mt-4 relative z-10">
            <p className="text-[16px] text-[#fff]">You own this skin. Use it now!</p>
            <button
              onClick={handleSelectAvatar}
              className={`font-medium text-[18px] text-[#fff] w-full h-[40px] rounded-[8px] bg-[#ffffff33] border-[1px] border-[#ffffff8f] mt-2 ${
                chooseAvatarStatus === 'pending' ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              {chooseAvatarStatus === "pending" ? 'Applying...' : 'Use this Skin'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
