import React from 'react'
import coin from "../../assets/img/home/coin.png"
export const ComboInput = () => {
  return (
    <div className="flex items-center h-[52px] w-full mb-[24px] rounded-[12px] mt-[30px] pl-[12px] pr-[5px] border-[1px] border-[#35333E]  blur-small-boxes">
        <input type="text" placeholder="Daily combo" className="flex-1 font-normal text-[18px] text-[#fff] bg-[transparent] h-full border-0 outline-none" />
        <button className="font-normal text-[18px] text-[#fff] w-[132px] h-[38px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px] flex items-center justify-center gap-2">
        <img src={coin} alt="" className="w-[20px] h-[20px]" />
        5000,000
    </button>
    </div>
  )
}
