import React from 'react'
import close from '../../assets/img/airdrop/close.svg'
import coin from "../../assets/img/home/coin.svg"
export const BuySpin = ({setbuyspin}) => {
  return (
    <div className="w-[430px] xs:w-full overflow-hidden h-[410px] bg-[#0C0E1A] border-t-[1px] border-t-[#3E4051] rounded-tl-[16px] rounded-tr-[16px] fixed bottom-0 left-1/2 -translate-x-1/2 z-50 flex flex-col">
<div className="px-[16px] py-[12px] flex items-center justify-between">
  <h1 className="text-[20px] font-bold text-[#FFFFFF]">Get more spin</h1>
  <img src={close} className="cursor-pointer"  onClick={e=>{
            setbuyspin(false)
           }} alt="" />
</div>
<div className="px-4 flex flex-col items-center text-center z-10  flex-1 pt-4">
  
<div className='flex flex-col gap-3 mt-7'>

<h1 className="text-[36px] text-[#FFFFFF] font-bold">Buy 5 spins</h1>
  <p className='text-[24px] font-normal text-[#FCD31F]'>
  For 
  </p>
  <h1 className="text-[36px] text-[#FFFFFF] font-bold flex items-center gap-2">
    <img src={coin} alt="" />
    100 Stars</h1>

</div>
    <div className="w-full flex-1 pb-8 flex items-end">
  <div className='grid grid-cols-2 gap-[10px] w-full'>
  <button className="font-bold text-[22px] text-[#fff] w-full  h-[52px] bg-[#3D3E49] rounded-[10px] border-[1px] border-[#35333E]" onClick={e=>{
            setbuyspin(false)
           }}>
    Cancel
    </button>
    <button className="font-bold text-[22px] text-[#fff] w-full  h-[52px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px]">
    Buy Now
    </button>
  </div>
    </div>
</div>

<span className='w-[248px] h-[248px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full absolute left-1/2 -translate-x-1/2 top-[200px] blur-[150px]'></span>

      </div>
  )
}
