import { Layout } from "../components/Layout";
import coin from "../assets/img/home/coin.png"
import { UpdateArea } from "../components/home/UpdateArea";
import { ComboTime } from "../components/card/ComboTime";
import { ComboInput } from "../components/card/ComboInput";
import { GiftCards } from "../components/card/GiftCards";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import mining from "../assets/img/card/mining.svg"
import equipment from "../assets/img/card/equipment.svg"
import infa from "../assets/img/card/infa.svg"
import nft from "../assets/img/card/nft.svg"
import bonus from "../assets/img/card/bonus.svg"
import support from "../assets/img/card/support-team.svg"
import market from "../assets/img/card/market-team.svg"
import security from "../assets/img/card/security-team.svg"

import { Badge } from "../components/card/Badge";
import { useEffect, useState } from "react";
import { registerUser } from "../api/profile";
import { userdata } from "../utils/Function";

export const Card = () => {
  const [user,setuser]=useState({})


  useEffect(() => {
    userdata.username=window.localStorage.getItem('username')
    registerUser(userdata,setuser)

  }, []);

  return (
    <Layout active={2} hideheaderbg={true}>

    <UpdateArea user={user}/>

    <div className="flex items-center justify-center gap-[6px]">
      <img src={coin} alt="" />
      <h1 className="text-[#fff] font-extra-bold text-[32px]">{user.coins}</h1>
    </div>

    <ComboInput/>

    <ComboTime user={user} setuser={setuser} />

    <GiftCards/>

<div className="mt-[24px]">
    <Splide options={ { autoWidth:true,pagination:false,arrows:false,gap:10 } } aria-label="React Splide Example">
      <SplideSlide>
        <Badge id="card-btn-equipment" text="Equipment" image={equipment} />
      </SplideSlide>
      <SplideSlide>
        <Badge id="card-btn-mining" text="Mining" image={mining} />
      </SplideSlide>
      
      <SplideSlide>
        <Badge id="card-btn-infrastructure" text="Infrastructure" image={infa} />
      </SplideSlide>

      <SplideSlide>
        <Badge id="card-btn-nft" text="NFT Cards" image={nft} />
      </SplideSlide>
      
    </Splide>


</div>


<div className="grid grid-cols-2 gap-3 mt-4">
    <div className="w-full h-[129px] border-[1px] border-[#35333E] p-2  blur-small-boxes rounded-[12px]">
    <div className="flex items-center gap-2 ">
        <img src={bonus} alt="" />
        <div>
            <h1 className="text-[#fff] text-[18px] font-semibold">Referral <br /> Bonus</h1>
            <p className="text-[16px] text-[#9A9BA0] font-normal">+140 / hour</p>
        </div>
    </div>
    <button className="flex items-center gap-[6px] w-full bg-[#3D3E49] h-[32px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
        <img src={coin} alt="" className="w-[20px] h-[20px]" />
        200
    </button>
    </div>

    <div className="w-full h-[129px] border-[1px] border-[#35333E] p-2  blur-small-boxes rounded-[12px]">
    <div className="flex items-center gap-2 ">
        <img src={security} alt="" />
        <div>
            <h1 className="text-[#fff] text-[18px] font-semibold">Security  <br /> Update</h1>
            <p className="text-[16px] text-[#9A9BA0] font-normal">+140 / hour</p>
        </div>
    </div>
    <button className="flex items-center gap-[6px] w-full bg-[#3D3E49] h-[32px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
        <img src={coin} alt="" className="w-[20px] h-[20px]" />
        450
    </button>
    </div>

    <div className="w-full h-[129px] border-[1px] border-[#35333E] p-2  blur-small-boxes rounded-[12px]">
    <div className="flex items-center gap-2 ">
        <img src={support} alt="" />
        <div>
            <h1 className="text-[#fff] text-[18px] font-semibold">Support  <br /> Team</h1>
            <p className="text-[16px] text-[#9A9BA0] font-normal">+140 / hour</p>
        </div>
    </div>
    <button className="flex items-center gap-[6px] w-full bg-[#3D3E49] h-[32px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
        <img src={coin} alt="" className="w-[20px] h-[20px]" />
        200
    </button>
    </div>

    <div className="w-full h-[129px] border-[1px] border-[#35333E] p-2  blur-small-boxes rounded-[12px]">
    <div className="flex items-center gap-2 ">
        <img src={market} alt="" />
        <div>
            <h1 className="text-[#fff] text-[18px] font-semibold">Marketing  <br /> team</h1>
            <p className="text-[16px] text-[#9A9BA0] font-normal">+140 / hour</p>
        </div>
    </div>
    <button className="flex items-center gap-[6px] w-full bg-[#3D3E49] h-[32px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
        <img src={coin} alt="" className="w-[20px] h-[20px]" />
        850
    </button>
    </div>
</div>
    </Layout>
  )
}

