import { Layout } from "../components/Layout";
import coin from "../assets/img/home/coin.svg"
import { UpdateArea } from "../components/home/UpdateArea";
import { ComboTime } from "../components/card/ComboTime";
import { ComboInput } from "../components/card/ComboInput";
import { GiftCards } from "../components/card/GiftCards";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import mining from "../assets/img/card/mining.svg"
import equipment from "../assets/img/card/equipment.svg"
import infa from "../assets/img/card/infa.svg"
import nft from "../assets/img/card/nft.svg"
import bonus from "../assets/img/card/bonus.svg"
import support from "../assets/img/card/support-team.svg"
import market from "../assets/img/card/market-team.svg"
import security from "../assets/img/card/security-team.svg"

import { Badge } from "../components/card/Badge";
import { useEffect, useState } from "react";
import { registerUser } from "../api/profile";
import { userdata } from "../utils/Function";
import { Link } from "react-router-dom";

export const HourCoin = () => {
  const [user,setuser]=useState({})


  useEffect(() => {
    userdata.username=window.localStorage.getItem('username')
    registerUser(userdata,setuser)

  }, []);

  return (
    <Layout active={1} hideheaderbg={true}>

    <UpdateArea user={user}/>

    <div className="flex items-center justify-center gap-[6px]">
      <img src={coin} alt="" />
      <h1 className="text-[#fff] font-extra-bold text-[32px]">+280 Per Hour</h1>
    </div>



    <div className="mt-[24px] flex flex-col gap-4">
    <p className="text-[18px] text-[#fff] text-center">
      To increase your coins per hour, buy new improvements.
      </p>
      <Link to="/card" className="border-[1px] border-[#35333E] flex items-center justify-center rounded-[10px] text-[#fff] text[16px] h-[40px]  blur-small-boxes">
        Go to card menu
      </Link>
      <p className="text-[18px] text-[#fff] text-center">
      You will make a coins even when you are offline for up to 3 hours. Log in more often to collect more coins.
      </p>
      <p className="text-[18px] text-[#fff] text-center">
      You can increase the offline time you are credited for by purchasing an Offline Boost from the Premium Store.
      </p>

      <Link  to="/" className="font-bold flex items-center justify-center text-[22px] text-[#fff] w-full h-[47.59px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] rounded-[10px]">
    Go to Store
    </Link>
    </div>
    </Layout>
  )
}

