import React, { useState } from 'react';
import { Layout } from "../components/Layout";
import energy from "../assets/img/boost/energy.png";
import coin from "../assets/img/home/coin.png";
import main from "../assets/img/boost/main.png";
import turbo from "../assets/img/boost/turbo.png";
import multi from "../assets/img/boost/multi-tap.png";
import energyLimit from "../assets/img/boost/energy-limit.png";
import { useEnergy } from "../context/EnergyContext";
import { useAuth } from '../context/AuthContext';

export const Boost = () => {
  // const [infostate, setInfo] = useState(false);
  // const navigate = useNavigate();
  const { energyStatus, loading, applyFullEnergy } = useEnergy();
  const { user,coinstate } = useAuth();
 const [isApplying, setIsApplying] = useState(false);
   // Apply energy and update the button status immediately
  const handleApplyFullEnergy = async () => {
    if (isApplying || energyStatus?.dailyRefills === 0 || energyStatus?.current === energyStatus?.max) {
      return; // Prevent multiple clicks or invalid actions
    }

    setIsApplying(true);
    try {
     
      await applyFullEnergy(); // Call the context function
    } catch (error) {
      console.error("Failed to apply full energy:", error);
    } finally {
      setIsApplying(false); // Re-enable the button after operation
    }
  };
  return (
    <Layout active={1} hideheaderbg={true}>
      {/* {infostate && <EnergyUpgrade setinfo={() => { setInfo(false); navigate('/home'); }} />} */}
              <div className="absolute inset-0 rounded-full border bg-[#c121e5c9] opacity-15 blur-[55px] h-[150px]" />

      <div className="pt-10">
        <img src={main} alt="" className="mx-auto" />
        <h1 className="text-[32px] text-[#FFFFFF] font-extra-bold text-center mt-5">Boost your Profit</h1>
      </div>

      <div className="flex flex-col gap-[12px] my-[24px]">
        <div className="border-[1px] flex flex-col items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2 mb-[24px]">
          <h1 className="text-[18px] font-normal text-[#fff]">Your total Balance</h1>
          <p className="flex items-center justify-center gap-[6px] text-[32px] font-extra-bold text-[#F4BE0F]">
            <img src={coin} className="w-[36px] h-[36px]" alt="" />
            {coinstate.toLocaleString()}
          </p>
        </div>

        <h1 className="text-[18px] font-bold text-[#fff] mb-[5px]">Free daily boosters</h1>

        <div className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={energy} alt="" />
          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="text-[16px] text-[#fff] font-bold">Full energy</h1>
              <p className="text-[16px] text-[#9A9BA0] font-normal">
                {energyStatus?.dailyRefills}/5 available
              </p>
            </div>
            <button
              onClick={handleApplyFullEnergy}
              className={`font-normal text-[16px] w-[106px] h-[35px] rounded-[10px] ${
                isApplying || energyStatus?.dailyRefills === 0 || energyStatus?.current === energyStatus?.max
                  ? "text-[#9A9BA0] bg-[#44424F]"
                  : "text-[#fff] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5]"
              }`}
              disabled={
                isApplying || 
                energyStatus?.dailyRefills === 0 ||
                energyStatus?.current === energyStatus?.max
              }
            >
              {"Apply"}
            </button>
          </div>
        </div>

        {/* Additional Boosters */}
        <div className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={turbo} alt="" />
          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="text-[16px] text-[#fff] font-bold">Turbo Recovery</h1>
              <p className="text-[16px] text-[#9A9BA0] font-normal">Available 1x Daily</p>
            </div>
            <button className="font-normal text-[16px] text-[#9A9BA0] w-[106px] h-[35px] bg-[#44424F] rounded-[10px]">
              Apply
            </button>
          </div>
        </div>

        <h1 className="text-[18px] font-bold text-[#fff] mb-[5px] mt-[18px]">Boosters</h1>
        <div className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={multi} alt="" />
          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="text-[16px] text-[#fff] font-bold">Multitap</h1>
              <p className="text-[16px] text-[#9A9BA0] font-normal">From level 2</p>
            </div>
            <button className="flex items-center gap-[6px] w-[106px] bg-[#3D3E49] h-[35px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
              <img src={coin} alt="" className="w-[20px] h-[20px]" />
              2000
            </button>
          </div>
        </div>

        <div className="border-[1px] flex items-center border-[#35333E] blur-small-boxes p-2 rounded-[12px] gap-2">
          <img src={energyLimit} alt="" />
          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="text-[16px] text-[#fff] font-bold">Energy limit</h1>
              <p className="text-[16px] text-[#9A9BA0] font-normal">From level 2</p>
            </div>
            <button className="flex items-center gap-[6px] w-[106px] bg-[#3D3E49] h-[35px] rounded-[8px] text-[#FFFFFF] text-[16px] justify-center mt-1 border-[1px] border-[#35333E]">
              <img src={coin} alt="" className="w-[20px] h-[20px]" />
              10000
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
