import React from 'react'
import { Menu } from './common/Menu'
export const Layout = ({ children, active, hideheaderbg, nomenu = null }) => {
  return (
    <div className='bg-[#15161B] '>
      
      <div className={`w-[430px] bg-gradient-to-b  h-screen overflow-y-auto  from-[#0E0F1B] to-[#0C0E1A]  pb-[150px] mx-auto rounded-tl-[12px] rounded-tr-[12px] relative ${nomenu && 'pb-[50px]'} xs:w-full `}>
        {/* <Header hideheaderbg={hideheaderbg} /> */}
        <div className='px-[16px]'>
          {children}
        </div>

        {
          nomenu == null && (
            <Menu active={active} />
          )
        }

      </div>
    </div>
  )
}
