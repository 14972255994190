import React, { useState } from 'react';

const MenuTooltip = ({ message, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowTooltip(true); // Show tooltip on click

    // Set a timer to hide the tooltip after 2 seconds
    setTimeout(() => setShowTooltip(false), 2000); // 2000 ms = 2 seconds
  };

  // Clone the child element and add the click handler
  const modifiedChild = React.cloneElement(children, {
    onClick: handleClick,
    'data-to': children.props.to, // Keep 'to' prop for reference but disable navigation
    to: undefined, // Remove 'to' to disable the navigation
    className: `${children.props.className} cursor-not-allowed`,
  });

  return (
    <div className="relative">
      {modifiedChild}
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-max z-50">
          <div className="bg-[#231F33] text-white text-sm py-2 px-4 rounded-full border border-[#504C5C] flex items-center">
            {message}
          </div>
          <div className="w-3 h-3 bg-[#231F33] border-r border-b border-[#504C5C] absolute left-1/2 -bottom-1.5 -translate-x-1/2 transform rotate-45"></div>
        </div>
      )}
    </div>
  );
};

export default MenuTooltip;
