import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { Home } from "./Home";
import { Loading } from "./pages/Loading";
import { Onboarding } from "./pages/Onboarding";
import { Card } from "./pages/Card";
import { Task } from "./pages/Task";
import { Invite } from "./pages/Invite";
import { AirDrop } from "./pages/AirDrop";
import { Boost } from "./pages/Boost";
import { Spin } from "./pages/Spin";
import { Avatar } from "./pages/Avatar";
import { UserInvite } from "./pages/UserInvite";
import { HourCoin } from "./pages/HourCoin";
import { Level } from "./pages/Level";
import { UserProvider } from "./context/UserContext";
import { AuthProvider } from "./context/AuthContext";
import { disableVerticalSwipes,miniAppBackgroundColor, enableClosingConfirmation, miniApp, expandViewport, init, isMiniAppMounted, isSwipeBehaviorMounted, miniAppHeaderColor, themeParams, backButton, swipeBehavior, closingBehavior, themeParamsHeaderBackgroundColor, setMiniAppHeaderColor } from "@telegram-apps/sdk";
import { LevelProvider } from "./context/LevelContext";
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import DailyRewardPage from "./pages/DailyRewards";
import { EnergyProvider } from "./context/EnergyContext";


const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV !== "local") {
  init();
 
  miniApp.mount();
  backButton.mount();
  themeParams.mount();
  swipeBehavior.mount();
  closingBehavior.mount();
  expandViewport(); 
   disableVerticalSwipes();
   enableClosingConfirmation();
   miniAppHeaderColor("#191126");
   miniAppBackgroundColor("#191126");
   themeParamsHeaderBackgroundColor("#191126");
   setMiniAppHeaderColor("#191126");
 
}
const checkOnboardingStatus = () => {
  const hasSkippedOnboarding = localStorage.getItem('hasSkippedOnboarding');
  return hasSkippedOnboarding ? '/home' : '/';
};

const router = createBrowserRouter([
  { path: "/", 
    
    element: checkOnboardingStatus() === '/home' ? <Navigate to="/home" replace /> : <Onboarding />,
   },
  { path: "/home", element: <Home /> },
  { path: "/coin/hour", element: <HourCoin /> },
  { path: "/level", element: <Level /> },
  { path: "/update-avatar", element: <Avatar /> },
  { path: "/card", element: <Card /> },
  { path: "/task", element: <Task /> },
  { path: "/daily-rewards", element: <DailyRewardPage /> },
  { path: "/invite", element: <Invite /> },
  { path: "/userInvite", element: <UserInvite /> },
  { path: "/airdrop", element: <AirDrop /> },
  { path: "/boost", element: <Boost /> },
  { path: "/spin", element: <Spin /> },
]);
const App = () => {
  // if (process.env.REACT_APP_ENV !== "local") {
  //   init();
  //   expandViewport();

  // }
  // React.useEffect(() => {
  //   if (process.env.REACT_APP_ENV !== "local") {
  //     // Define a retry mechanism for checking mini-app mounting
  //     const setupMiniAppFeatures = () => {
  //       if (isSwipeBehaviorMounted()) {
  //         disableVerticalSwipes();
  //         // enableClosingConfirmation();
  //         // miniAppHeaderColor("#9F23EF");
  //         // console.log("Mini app settings applied.");
  //       } else {
  //         console.log("Mini app not yet mounted. Retrying...");
  //         setTimeout(setupMiniAppFeatures, 100); // Retry every 100ms
  //       }
  //     };
      
  //     setupMiniAppFeatures();
  //   }
  // }, []);
React.useEffect(() => {
  const disableRightClick = (event) => {
    if (event.target.tagName === 'IMG') {
      event.preventDefault();
    }
  };
  document.addEventListener('contextmenu', disableRightClick);

  return () => {
    document.removeEventListener('contextmenu', disableRightClick);
  };
}, []);

  return <RouterProvider router={router} />;
};


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>

          <LevelProvider>

            <EnergyProvider>
              <App />
            </EnergyProvider>

          </LevelProvider>

        </UserProvider>

      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
