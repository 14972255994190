import React, { useState } from 'react';
import closeIcon from '../assets/img/airdrop/close.svg';
import { CheckCheckIcon } from 'lucide-react';
export const TaskModal = ({ task, onClose, onCheck, isLoadingFollow, isLoadingCheck, onFollow }) => {
    const [hasFollowed, setHasFollowed] = useState(false);

    const handleFollow = () => {
        if (!task.actionLink || hasFollowed) return;

        setHasFollowed(true);
        onFollow();
    };

    return (
        <div className="w-[430px] xs:w-full overflow-hidden h-[587px] bg-[#0C0E1A] border-t-[1px] border-t-[#3E4051] rounded-tl-[16px] rounded-tr-[16px] fixed bottom-0 left-1/2 -translate-x-1/2 z-50 flex flex-col">
            <div className="px-[16px] py-[12px] flex items-center justify-between">
                <h1 className="text-[20px] font-bold text-[#FFFFFF]">{task.title}</h1>
                <img src={closeIcon} className="cursor-pointer" onClick={onClose} alt="Close" />
            </div>

            <div className="px-4 z-50 flex flex-col items-center text-center flex-1 pt-4">
                {/* <img src={coinIcon} alt="Task Icon" /> */}
                <img src={require(`../assets/img/task/${task.iconXL}`)} alt="Task Icon" className="w-[48px] h-[48px] mt-4" />
                <h1 className="text-[36px] text-white font-bold mt-2">{task.title}</h1>
                <p className="font-normal text-[18px] text-[#9A9BA0] mt-4">{task.description}</p>

                {/* Follow Button with Loader */}
                {task.actionLink && task.taskType !== 'invite' && (
                    <button
                        onClick={handleFollow}
                        disabled={isLoadingFollow || hasFollowed}
                        className={` mt-8 w-[150px] h-[52px] ${isLoadingFollow || hasFollowed ? 'bg-gray-500' : 'bg-gradient-to-tr from-[#C121E5] to-[#8B25F5]'
                            } rounded-[10px] text-white text-[22px] font-bold`}
                    >
                        {isLoadingFollow ? 'Opening...' : hasFollowed ? 'Opened' : task.taskType === 'follow' ? 'Follow' : 'Subscribe'}
                    </button>
                )}

                {/* Invite Text */}
                {task.taskType === 'invite' && (
                    <p className="mt-8 text-white text-[18px] font-normal">
                        Navigate to the Friend page and invite through your link.
                    </p>
                )}

                {/* Check Button with Loader */}
                <button
                    onClick={onCheck}
                    disabled={(!hasFollowed || isLoadingCheck )&& task.taskType !== 'invite'}
                    className={`w-[150px] mt-4 h-[52px] ${!hasFollowed || isLoadingCheck ? 'bg-[#208916] text-gray-100' : 'bg-[#208916] text-white'
                        } border border-[#208916] rounded-[10px] text-[22px] font-bold`}
                >
                    {isLoadingCheck ? 'Checking...' : 'Check'}
                </button>
            </div>

            <span className="w-[248px] z-20 h-[248px] bg-gradient-to-b from-[#FCD31F] to-[#8A2BEB] block rounded-full absolute left-1/2 -translate-x-1/2 top-[200px] blur-[150px]"></span>
            
        </div>
    );
};