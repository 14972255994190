// src/api/energy.js
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../hooks/axiosInstance';

export const fetchEnergyStatus = async () => {
  const response = await axiosInstance.get('/api/energy/status');
  return response.data;
};

const applyFullEnergy = async () => {
  const response = await axiosInstance.post('/api/energy/apply');
  return response.data;
};

// Hook to fetch energy status
export const useGetEnergyStatus = () => {
  return useQuery({
    queryKey: ['energyStatus'],
    queryFn: fetchEnergyStatus,
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
    retry: false,
    onSuccess: (data) => {
      console.log('Fetched energy status:', data);
    },
    onError: (error) => {
      console.error('Error fetching energy status:', error);
    },
  });
};

// Hook to apply full energy refill
export const useApplyFullEnergy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: applyFullEnergy,
    onSuccess: (data) => {
      console.log('Energy applied successfully:', data);
      queryClient.invalidateQueries(['energyStatus']); // Refresh energy status after applying
    },
    onError: (error) => {
      console.error('Error applying energy:', error);
    },
  });
};

// Function to update energy in the backend
const updateEnergy = async (newEnergy) => {
  const response = await axiosInstance.post('/api/energy/update', { newEnergy }); // Ensure only { newEnergy: value }
  return response.data;
};

// React Query Hook for updating energy
export const useUpdateEnergy = () => {
  return useMutation({
    mutationFn: (newEnergy) => updateEnergy(newEnergy), // Pass only the number, not an object
    onSuccess: (data) => {
      console.log('Energy updated successfully:', data);
    },
    onError: (error) => {
      console.error('Error updating energy:', error);
    },
  });
};