import axios from "axios"
import axiosInstance from "../hooks/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// const URL="http://localhost:3000"
const URL="https://twinkle-backend.vercel.app"
// src/api/reward.js
// Fetch Daily Reward
const getDailyReward = async () => {
  const response = await axiosInstance.get('/api/reward/daily');
  return { ...response.data, requestTime: Date.now() }; // Add requestTime for tracking
};

// Claim Daily Reward
const claimDailyReward = async () => {
  const response = await axiosInstance.post('/api/reward/daily/claim');
  return { ...response.data, requestTime: Date.now() }; // Add requestTime for tracking
};

// React Query Hook for fetching daily reward
export const useGetDailyReward = () => {
  return useQuery({
    queryKey: ['dailyReward'],
    queryFn: getDailyReward,
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
    retry: false, // Do not retry on failure
    onSuccess: (data) => {
      console.log('Fetched daily reward:', data);
    },
    onError: (error) => {
      console.error('Error fetching daily reward:', error);
    },
  });
};

// React Query Hook for claiming daily reward
export const useClaimDailyReward = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: claimDailyReward,
    onSuccess: (data) => {
      console.log('Daily reward claimed successfully:', data);
      queryClient.setQueryData(['dailyReward'], data); // Directly set new reward data
    },
    onError: (error) => {
      console.error('Error claiming daily reward:', error);
    },
  });
};

export const getDailyCombo=async (data,setRemainingTime)=>{
    const response=await axios.post(`${URL}/api/get-next-reward-combo`,data)
    setRemainingTime(response['data']['nextComboTime'])  
}


export const getSpinToWin=async (data,setRemainingTime)=>{
    const response=await axios.post(`${URL}/api/get-next-spin`,data)
    setRemainingTime(response['data']['nextSpinTime'])  
}






// export const claimDailyReward=async (data,setuser,setavailable)=>{
//     const response=await axios.post(`${URL}/api/claim-daily-reward`,data)
//     console.log(response)
//     setuser(response['data']['message'])
//     setavailable(false)
// }



export const claimComboReward=async (data,setuser,setavailable)=>{
    const response=await axios.post(`${URL}/api/claim-combo`,data)
    console.log(response)
    setuser(response['data']['message'])
    setavailable(false)
}


export const claimSpin=async (data,navigation)=>{
    const response=await axios.post(`${URL}/api/claim-spin-reward`,data)
    navigation("/home")
}

