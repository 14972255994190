import React, { useEffect, useState } from 'react'
import { getDailyCombo } from '../../api/reward';
import { Link } from 'react-router-dom';
import gift from "../../assets/img/home/gift.png"
export const DailyComboBox = ({ img, type, imgstyle = null, user }) => {

  const [remainingTime, setRemainingTime] = useState(null);
  const [formateTime, setFormateTime] = useState(null);
  const [RewardActive, setRewardActive] = useState(null);

  function convertMillisecondsToTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return {
      hours,
      minutes,
      seconds: secs,
    };
  }


  useEffect(() => {

    let intervalId;
    if (remainingTime != null) {




      intervalId = setInterval(() => {
        const nextRewardTime = new Date(remainingTime);
        const currentTime = new Date(Date.now());

        const differenceInMilliseconds = nextRewardTime - currentTime;

        // Check if current time is greater than or equal to next reward time
        if (differenceInMilliseconds <= 0) {
          clearInterval(intervalId); // Stop the interval
          setFormateTime({ hours: 0, minutes: 0, seconds: 0 }); // Optionally reset time
          setRewardActive(true)
          return;
        }

        const time = convertMillisecondsToTime(differenceInMilliseconds);
        setFormateTime(time);

      }, 1000);





    }

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);

  }, [remainingTime])


  // useEffect(() => {

  //   if (user.coins) {

  //     getDailyCombo({
  //       username: user.username
  //     }, setRemainingTime)
  //   }



  // }, [user])


  return (
    <div className='flex flex-col items-center justify-center relative'>

      <div className='w-full blur-small-boxes h-[110px] rounded-[12px] boxBorder flex flex-col items-center justify-center z-10 '>
        <img  className='h-8' src={gift} loading="lazy" alt="" />
        <h1 className='text-[14px] dailyBonusBtnTitle text-[#D2D3D9] mt-2'>{type}</h1>


        <Link to="" className="font-medium text-[12px] gap-2 text-[#fff]  w-[70px] h-[25px] rounded-[8px] bg-gradient-to-tr from-[#C121E5] to-[#8B25F5] mt-1 flex items-center justify-center">

          Claim
        </Link>

      </div>
    </div>
  )
}
