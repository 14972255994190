import axiosInstance from "../hooks/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const URL = process.env.REACT_APP_API_URL;

// Fetch all tasks for the authenticated user
export const fetchUserTasks = async () => {
  const response = await axiosInstance.get(`${URL}/api/user/tasks`);
  return response.data;
};

// Mark a task as completed and credit coins
const completeTask = async (data) => {
  const response = await axiosInstance.post(`${URL}/api/tasks/complete`, data);
  return response.data;
};

export const useUserTasks = () => {
  return useQuery({
    queryKey: ["userTasks"],
    queryFn: fetchUserTasks,
    staleTime: 60000, 
  });
};

export const useCompleteTask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: completeTask,
    onSuccess: (data) => {
      console.log("Task completed successfully:", data);
      queryClient.invalidateQueries({ queryKey: ["userTasks"] }); 
      queryClient.invalidateQueries({ queryKey: ["user"] }); 
    },
    onError: (error) => {
      console.error("Error completing task:", error);
    },
  });
};
